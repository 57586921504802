<template>
  <div class="views-main">
      <div class="views-main-title">
          <p class="txt">检测报告管理</p>
      </div>
      <div class="tableBox" style="top:50px;">
          <div class="views-main-search">
              <el-input v-model="name" placeholder="请输入附件名称" class="iw"></el-input>
              <el-select v-model="project" placeholder="选择项目" filterable clearable class="iw">
                <el-option v-for="item in projectList" :key="item.SNOWID" :label="item.NAME"
                          :value="item.SNOWID"></el-option>
                </el-select>
              <el-select v-model="serveteam" placeholder="选择服务组织" filterable clearable class="iw">
                <el-option v-for="item in teamList" :key="item.SYSUSERSNOWID" :label="item.DisplayName"
                          :value="item.SYSUSERSNOWID"></el-option>
                </el-select>
              <el-button type="primary" @click="searchPost">查询</el-button>
              <el-button v-if="sysType == 1" type="success" @click="addPost" class="fr">添加</el-button>
          </div>
          <div class="views-main-body">
              <el-table :data="postList" stripe style="width: 100%">
                  <el-table-column prop="FileName" label="文件名称"></el-table-column>
                  <el-table-column prop="ProjectName" label="项目"></el-table-column>
                  <el-table-column prop="SysUserName" label="服务组织"></el-table-column>
                  <el-table-column label="操作" width="200">
                      <template slot-scope="scope">
                          <el-button v-if="sysType == '1'" @click="editPost(scope.row)" type="text" size="small"
                              style="color:#E6A23C">编辑</el-button>
                              <el-button v-if="sysType == '1'" @click="downPost(scope.row)" type="text" size="small"
                              style="color:#409EFF">下载附件</el-button>
                          <el-button v-if="sysType == '1'" @click="delPost(scope.row)" type="text" size="small"
                              style="color:#F56C6C">删除</el-button>
                      </template>
                  </el-table-column>
              </el-table>
              <el-pagination background layout="total, prev, pager, next" :current-page="PageIndex" :page-size="PageSize"
                  :total="Total" class="pg" @current-change="PGChange"></el-pagination>
          </div>
      </div>
      <el-dialog :title="doTypeStr" :visible.sync="postModal" width="900px" top="30px" :close-on-click-modal="false">
          <el-form :model="postInfo" status-icon :rules="postrules" ref="uForm" label-width="150px" class="demo-uForm">
              <el-form-item label="文件名" prop="filename">
                  <el-input v-model="postInfo.filename" autocomplete="off" style="width:69%"></el-input>
                  <el-select v-model="ysblx" style="width:30%;float:right;" >
                      <el-option label=".zip" value="1"></el-option>
                      <el-option label=".rar" value="2"></el-option>
                  </el-select>
              </el-form-item>
              <el-form-item label="服务组织" prop="sysusersnowid">
                  <el-select v-model="postInfo.sysusersnowid" style="width:100%;" >
                      <el-option v-for="item in teamList" :key="item.SYSUSERSNOWID" :label="item.DisplayName"
                          :value="item.SYSUSERSNOWID"></el-option>
                  </el-select>
              </el-form-item>
              <el-form-item label="项目" prop="projectsnowid">
                  <el-select v-model="postInfo.projectsnowid" style="width:100%;" >
                      <el-option v-for="item in projectList" :key="item.SNOWID" :label="item.NAME"
                          :value="item.SNOWID"></el-option>
                  </el-select>
              </el-form-item>
              <el-form-item label="检测报告" style="overflow:hidden;" prop="loadfile">
                    <div style="width:100%;float:left;">
                        <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
                            :show-file-list="false" :before-upload="beforeYsb" accept='.zip,.rar'>
                            <p v-if="postInfo.loadfile==0" style="color:#F56C6C;">请点击选择检测报告文件</p>
                            <p v-else ><span style="color:#333333;">{{filenamestr}}</span>　<span style="color:#409EFF;">点击重选</span></p>
                        </el-upload>
                    </div>
                </el-form-item>
              <el-form-item>
                  <el-button type="primary" @click="submitPostForm()">提交</el-button>
                  <el-button @click="postModal = false">取消</el-button>
              </el-form-item>
          </el-form>
      </el-dialog>
  </div>
</template>
<script>
export default {
  name: "name",
  data() {
      var filename = (rule, value, callback) => {
          if (value === "") {
              callback(new Error("请输入文件名"));
          }else {
              callback();
          }
      };
      var sysusersnowid= (rule, value, callback) => {
          if (value === "") {
              callback(new Error("请选择服务组织"));
          } else {
              callback();
          }
      };
      var projectsnowid= (rule, value, callback) => {
          if (value === "") {
              callback(new Error("请选择项目"));
          } else {
              callback();
          }
      };
      var loadfile= (rule, value, callback) => {
          if (value === 0) {
              callback(new Error("请选择压缩包文件"));
          } else {
              callback();
          }
      };
      return {
          sysType: "1",
          canDo: true,

          project:"",
          year:null,
          name:"",
          serveteam:"",

          projectList:[],
          teamList:[],

          postList: [],
          PageIndex: 1,
          PageSize: 10,
          Total: 0,

          ysblx:"1",
          doTypeStr: "",
          doType: "",
          postModal: false,
          postInfo: {},
          postrules: {
              filename: [{ validator: filename, trigger: "blur" }],
              sysusersnowid: [{ validator: sysusersnowid, trigger: "blur" }],
              projectsnowid: [{ validator: projectsnowid, trigger: "blur" }],
              loadfile: [{ validator: loadfile, trigger: "blur" }],
          },
          filenamestr:"",
          fileItem:null,
      };
  },
  mounted() {
      // this.sysType = this.$store.state.sysType
      // if (this.sysType == "2") {
      //     this.regId = this.$store.state.xzqSnowid
      //     this.regUserId = this.$store.state.xzqUserSnowid
      // }
      this.loadItem = null
      this.getTeamList()
      this.getProjectList()
      this.getPostList()
  },
  methods: {
      beforeYsb(file) {
            // const isJPG = file.type === 'application/zip' ||;
            // if (!isJPG) {
            //     this.$message.error('上传图片只能是压缩包格式!');
            // } else {
            //     this.fileItem = file
            //     this.postInfo.loadfile=1
            // }
            var isJPG=true
            this.fileItem = file
            this.postInfo.loadfile=1
            this.filenamestr=file.name
            var lstr=file.name.substring(file.name.length - 3)
            if(lstr=="zip"){
              this.ysblx="1"
            }else if(lstr=="rar"){
              this.ysblx="2"
            }
            return isJPG;
      },
      upLoadFile(sourceid) {
            var fd = new FormData();
            fd.append("file", this.fileItem);
            this.$upload.post("File/UpLoadFileNew?SourceSnowID=" + sourceid + "&FileType=7", fd)
                .then((response) => {
                    //this.hideLoading()
                    if (response.data.SUCCESS) {
                        //
                    } else {
                        //
                    }
                })
                .catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                    this.hideLoading()
                });
      },
      getTeamList() {
          this.$http.post("CooperativeUser/GetDataList", {})
              .then((response) => {
                  this.hideLoading()
                  if (response.data.SUCCESS) {
                      this.teamList = response.data.DATA
                  }
              }).catch((error) => {
                  this.errorFlag(JSON.stringify(error))
              });
      },
      getProjectList() {
          this.$http.post("Project/GetDataList", {})
              .then((response) => {
                  this.hideLoading()
                  if (response.data.SUCCESS) {
                      this.projectList = response.data.DATA
                  }
              }).catch((error) => {
                  this.errorFlag(JSON.stringify(error))
              });
      },
      getPostList() {
          this.showLoading("请稍后")
          var params = {
              pageIndex: this.PageIndex,
              pageSize: this.PageSize,
              sysUserSnowID: this.serveteam,
              projectSnowID: this.project,
              fileName:this.name
          }
          // if(this.sysType == 2){
          //   params.regionSnowID = this.regId
          // }
          this.$http.post("JobReport/GetDataList", params)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.postList = response.data.DATA
                    this.Total = response.data.TOTALCOUNT
                } else {
                    this.postList = []
                    this.Total = 0
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.errorFlag(JSON.stringify(error))
                this.hideLoading()
                this.postList = []
                this.Total = 0
            });
      },
      searchPost() {
          this.PageIndex = 1
          this.getPostList()
      },
      getysbtype(){
        var str=""
        if(this.ysblx=="1"){
          str=".zip"
        }else{
          str=".rar"
        }
        return str
      },
      addPost() {
          this.postInfo = {
              filename: "",
              sysusersnowid: "",
              projectsnowid: "",
              loadfile:0,
          }
          this.ysblx="1"
          this.filenamestr=""
          this.fileItem=null
          this.doTypeStr = "添加检测报告"
          this.doType = "1"
          this.postModal = true
      },
      sendAdd() {
          this.canDo = false
          this.showLoading("请稍后")
          var params={
            filename:this.postInfo.filename+this.getysbtype(),
            sysusersnowid:this.postInfo.sysusersnowid,
            projectsnowid:this.postInfo.projectsnowid,
          }
          this.$http.post("JobReport/InsertData", params)
              .then((response) => {
                  this.canDo = true
                  this.hideLoading()
                  if (response.data.SUCCESS) {
                      this.$message.success("已添加")
                      this.postModal = false
                      this.getPostList()
                      this.upLoadFile(response.data.DATA)
                  } else {
                      this.$message.error(response.data.MSG)
                  }
              })
              .catch((error) => {
                  this.errorFlag(JSON.stringify(error))
                  this.$message.error("请求出错")
                  this.hideLoading()
                  this.canDo = true
              });
      },
      editPost(item) {
         var fnames=item.FileName.split(".")
          this.postInfo = {
              snowid: item.SnowID,
              filename: fnames[0],
              sysusersnowid: item.SysUserSnowID,
              projectsnowid: item.ProjectSnowID,
              loadfile:1,
          }
          if(fnames[1]==".zio"){
            this.ysblx=="1"
          }else{
            this.ysblx=="2"
          }
          this.filenamestr=item.FileName
          this.fileItem=null
          this.doTypeStr = "修改检测报告"
          this.doType = "2"
          this.postModal = true
      },
      sendEdit() {
          this.canDo = false
          this.showLoading("请稍后")
          var params={
            snowid:this.postInfo.snowid,
            filename:this.postInfo.filename+this.getysbtype(),
            sysusersnowid:this.postInfo.sysusersnowid,
            projectsnowid:this.postInfo.projectsnowid,
          }
          this.$http.post("JobReport/UpdateData", params)
              .then((response) => {
                  this.canDo = true
                  this.hideLoading()
                  if (response.data.SUCCESS) {
                      this.$message.success("已修改")
                      this.postModal = false
                      this.getPostList();
                      if(this.fileItem){
                        this.upLoadFile(this.postInfo.snowid)
                      }
                  } else {
                      this.$message.error(response.data.MSG)
                  }
              })
              .catch((error) => {
                  this.errorFlag(JSON.stringify(error))
                  this.$message.error("请求出错")
                  this.hideLoading()
                  this.canDo = true
              });
      },
      delPost(item) {
          if (this.canDo) {
              this.$confirm('确定删除：' + item.FileName + '检测报告？', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
              }).then(() => {
                  this.sendDel(item.SnowID)
              }).catch(() => {
                  this.$message.info("已取消")
              });
          }

      },
      sendDel(id) {
          this.canDo = false
          this.showLoading("请稍后")
          this.$http.post("JobReport/DeleteData", { SnowID: id })
              .then((response) => {
                  this.canDo = true
                  this.hideLoading()
                  if (response.data.SUCCESS) {
                      this.$message.success("已删除")
                      this.postModal = false
                      this.getPostList()
                  } else {
                      this.$message.error(response.data.MSG)
                  }
              })
              .catch((error) => {
                  this.errorFlag(JSON.stringify(error))
                  this.$message.error("请求出错")
                  this.hideLoading()
                  this.canDo = true
              });
      },
      PGChange(e) {
          this.PageIndex = e
          this.getPostList()
      },
      submitPostForm() {
          if (this.canDo) {
              this.$refs.uForm.validate((valid) => {
                  if (valid) {
                      if (this.doType == "1") {
                          this.sendAdd()
                      } else if (this.doType == "2") {
                          this.sendEdit()
                      }
                  } else {
                      return false;
                  }
              });
          }
      },
      downPost(item){
        this.canDo = false
        this.showLoading("请稍后")
        var params={
          SnowID:item.SnowID
        }
        this.$download.post("JobReport/DownLoadJobReport", params,{ responseType: 'blob',timeout:300000})
            .then((resJson) => {
              if (resJson) {
                  var namenew=item.FileName
                  let blob = new Blob([resJson.data], { type: 'application/octet-stream' })
                  if (window.navigator.msSaveOrOpenBlob) {
                      //兼容ie
                      if (this.form.name) {
                          window.navigator.msSaveBlob(blob, namenew)
                      } else {
                      window.navigator.msSaveBlob(blob, namenew)
                      }
                  } else {
                      let downloadElement = document.createElement('a')
                      let href = window.URL.createObjectURL(blob) //创建下载的链接
                      downloadElement.href = href

                      downloadElement.download = namenew

                      document.body.appendChild(downloadElement)
                      //此写法兼容火狐
                      let evt = document.createEvent('MouseEvents')
                      evt.initEvent('click', false, false)
                      downloadElement.dispatchEvent(evt)

                      document.body.removeChild(downloadElement)
                  }
                  this.$message.success('文件已下载!')
              } else {
                  this.$message.error("文件下载失败")
              }
              this.hideLoading()
              this.canDo = true
            })
            .catch((error) => {
                this.hideLoading()
                this.canDo = true
            });
      },

  },
  computed: {
  },
  watch: {
  },
};
</script>
<style ></style>