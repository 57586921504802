<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<style scoped>
  #app{position: absolute;left: 0;top: 0;right: 0;bottom: 0;}
</style>
<script>
export default {
  name: "App",
    mounted() {
    this.$cookies.config('30d')
  },
}
</script>