import { render, staticRenderFns } from "./ContractStatistics.vue?vue&type=template&id=2a1bb460&scoped=true&"
import script from "./ContractStatistics.vue?vue&type=script&lang=js&"
export * from "./ContractStatistics.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a1bb460",
  null
  
)

export default component.exports