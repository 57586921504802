<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">农具管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">

                <el-input v-model="name" placeholder="请输入名称" class="iw"></el-input>
                <el-select v-model="njType" placeholder="请选择农具类型" filterable clearable class="iw">
                    <el-option v-for="item in typeList" :key="item.SNOWID" :label="item.NAME"
                        :value="item.SNOWID"></el-option>
                </el-select>
                <el-select v-model="njBarnd" placeholder="请选择农具品牌" filterable clearable class="iw">
                    <el-option v-for="item in brandList" :key="item.SNOWID" :label="item.NAME"
                        :value="item.SNOWID"></el-option>
                </el-select>
                <el-select v-model="crop" placeholder="请选择农作物" filterable clearable class="iw">
                    <el-option v-for="item in cropList" :key="item.SNOWID" :label="item.NAME"
                        :value="item.SNOWID"></el-option>
                </el-select>
                <el-select v-model="link" placeholder="请选择服务环节" filterable clearable class="iw">
                    <el-option label="耕（旋耕）" value="0"></el-option>
                    <el-option label="种（机播）" value="1"></el-option>
                    <el-option label="防（防治）" value="2"></el-option>
                    <el-option label="收（收割）" value="3"></el-option>
                    <el-option label="耕（深翻）" value="4"></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%">
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="OWERNAME" label="所有人"></el-table-column>
                    <el-table-column prop="OWERPHONE" label="联系电话"></el-table-column>
                    <el-table-column prop="BREADTH" label="幅宽"></el-table-column>
                    <el-table-column prop="SYSUSERNAME" label="服务组织用户"></el-table-column>
                    <el-table-column prop="SERVICELINK" label="服务环节">
                        <template slot-scope="scope">
                            <span v-if="scope.row.SERVICELINK == '0'">耕（旋耕）</span>
                            <span v-else-if="scope.row.SERVICELINK == '1'">种（机播）</span>
                            <span v-else-if="scope.row.SERVICELINK == '2'">防（防治）</span>
                            <span v-else-if="scope.row.SERVICELINK == '3'">收（收割）</span>
                            <span v-else-if="scope.row.SERVICELINK == '4'">耕（深翻）</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="TOOLTYPENAME" label="农具类型"></el-table-column>
                    <el-table-column prop="TOOLBRAND" label="农具品牌"></el-table-column>
                    <el-table-column prop="CROPTYPE" label="农作物类型"></el-table-column>
                    <el-table-column prop="BUYYEAR" label="购买日期">
                        <template slot-scope="scope">
                            <span>{{ scope.row.BUYYEAR ? scope.row.BUYYEAR.substring(0, 10) : '/' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small"
                                style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)" type="text" size="small"
                                style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next" :current-page="PageIndex" :page-size="PageSize"
                    :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="900px" top="30px" :close-on-click-modal="false">
            <el-form :model="postInfo" status-icon :rules="postrules" ref="uForm" label-width="150px" class="demo-uForm">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="postInfo.name" autocomplete="off"></el-input>
                </el-form-item>
              <el-form-item label="所有人" prop="OwerName">
                <el-input v-model="postInfo.OwerName" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="联系电话" prop="OwerPhone">
                <el-input v-model="postInfo.OwerPhone" autocomplete="off"></el-input>
              </el-form-item>
                <el-form-item label="幅宽" prop="breadth">
                    <el-input v-model="postInfo.breadth" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="服务组织用户" prop="SYSUSERSNOWID">
                    <el-select v-model="postInfo.SYSUSERSNOWID" style="width:100%;" clearable>
                        <el-option v-for="item in teamList" :key="item.SYSUSERSNOWID" :label="item.DisplayName"
                            :value="item.SYSUSERSNOWID"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="农具类型" prop="tooltypesnowid">
                    <el-select v-model="postInfo.tooltypesnowid" style="width:100%;" @change="typeChange">
                        <el-option v-for="item in typeList" :key="item.SNOWID" :label="item.NAME"
                            :value="item.SNOWID"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="农具品牌">
                    <el-select v-model="postInfo.TOOLBRANDSNOWID" style="width:100%;" @change="brandChange">
                        <el-option v-for="item in brandList" :key="item.SNOWID" :label="item.NAME"
                            :value="item.SNOWID"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="服务环节" prop="servicelink">
                    <el-select v-model="postInfo.servicelink" style="width:100%;">
                        <el-option label="耕（旋耕）" value="0"></el-option>
                        <el-option label="种（机播）" value="1"></el-option>
                        <el-option label="防（防治）" value="2"></el-option>
                        <el-option label="收（收割）" value="3"></el-option>
                        <el-option label="耕（深翻）" value="4"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="农作物类型" prop="croptypesnowid">
                    <el-select v-model="postInfo.croptypesnowid" style="width:100%;" @change="cropChange">
                        <el-option v-for="item in cropList" :key="item.SNOWID" :label="item.NAME"
                            :value="item.SNOWID"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="购买日期">
                    <el-date-picker v-model="postInfo.buyyears" type="date" placeholder="选择日期" style="width:100%;">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: "name",
    data() {
        var name = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入名称"));
            } else {
                callback();
            }
        };
        var OwerName = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入所有人名称"));
            } else {
                callback();
            }
        };
        var OwerPhone = (rule, value, callback) => {
          let reg = /^1[3-9][0-9]{9}$/
          if (value === "") {
            callback(new Error("请输入电话号码"));
          } else {
            if(!reg.test(value)){
              callback(new Error("请输入正确的电话号码"));
            }else{
              callback();
            }
          }
        };
        var tooltypesnowid = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请选择农具类型"));
            } else {
                callback();
            }
        };
        var TOOLBRANDSNOWID = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请选择农具品牌"));
            } else {
                callback();
            }
        };
        var croptypesnowid = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请选择农作物类型"));
            } else {
                callback();
            }
        };
        var servicelink = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请选择服务环节"));
            } else {
                callback();
            }
        };
        var breadth = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入幅宽"));
            } else if (typeof value != 'number' && isNaN(value)) {
                callback(new Error('幅宽只能是数字'));
            } else {
                callback();
            }
        };
        var buyyears = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请选择购买日期"));
            } else {
                callback();
            }
        };
        var SYSUSERSNOWID = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请选择服务组织"));
            } else {
                callback();
            }
        };
        return {
            canDo: true,

            sysType: "1",
            regId: "",
            regUserId: "",

            name: "",
            njType: "",
            njBarnd: "",
            crop: "",
            link: "",

            typeList: [],
            brandList: [],
            teamList: [],
            cropList: [],

            postList: [],
            PageIndex: 1,
            PageSize: 10,
            Total: 0,


            doTypeStr: "",
            doType: "",
            postModal: false,
            postInfo: {},
            postrules: {
                name: [{ validator: name, trigger: "blur" }],
                tooltypesnowid: [{ validator: tooltypesnowid, trigger: "blur" }],
                TOOLBRANDSNOWID: [{ validator: TOOLBRANDSNOWID, trigger: "blur" }],
                croptypesnowid: [{ validator: croptypesnowid, trigger: "blur" }],
                servicelink: [{ validator: servicelink, trigger: "blur" }],
                buyyears: [{ validator: buyyears, trigger: "blur" }],
                SYSUSERSNOWID: [{ validator: SYSUSERSNOWID, trigger: "blur" }],
                breadth: [{ validator: breadth, trigger: "blur" }],
                OwerName: [{ validator: OwerName, trigger: "blur" }],
                OwerPhone: [{ validator: OwerPhone, trigger: "blur" }],
            },
        };
    },
    mounted() {
        this.sysType = this.$store.state.sysType
        if (this.sysType == "2") {
            this.regId = this.$store.state.xzqSnowid
            this.regUserId = this.$store.state.xzqUserSnowid
        }
        this.loadItem = null
        this.getTypeList()
        this.getBrandList()
        this.getTeamList()
        this.getCropList()
        this.getPostList()
    },
    methods: {
        getTeamList() {
            this.$http.post("CooperativeUser/GetDataList", { ParentSnowID: this.regUserId })
                .then((response) => {
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.teamList = response.data.DATA
                    }
                }).catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                });
        },
        typeChange(e) {
            if (e == "") {
                this.postInfo.tooltypename = ""
            } else {
                for (var i = 0; i < this.typeList.length; i++) {
                    if (this.typeList[i].SNOWID == e) {
                        this.postInfo.tooltypename = this.typeList[i].NAME
                        break;
                    }
                }
            }
        },
        getTypeList() {
            this.$http.post("ToolType/GetDataList", {})
                .then((response) => {
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.typeList = response.data.DATA
                    }
                }).catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                });
        },
        brandChange(e) {
            if (e == "") {
                this.postInfo.toolbrand = ""
            } else {
                for (var i = 0; i < this.brandList.length; i++) {
                    if (this.brandList[i].SNOWID == e) {
                        this.postInfo.toolbrand = this.brandList[i].NAME
                        break;
                    }
                }
            }
        },
        getBrandList() {
            this.$http.post("ToolBrand/GetDataList", {})
                .then((response) => {
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.brandList = response.data.DATA
                    }
                }).catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                });
        },
        cropChange(e) {
            if (e == "") {
                this.postInfo.croptype = ""
            } else {
                for (var i = 0; i < this.cropList.length; i++) {
                    if (this.cropList[i].SNOWID == e) {
                        this.postInfo.croptype = this.cropList[i].NAME
                        break;
                    }
                }
            }
        },
        getCropList() {
            this.$http.post("CropsType/GetDataList", {})
                .then((response) => {
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.cropList = response.data.DATA
                    }
                }).catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                });
        },
        getPostList() {
            this.showLoading("请稍后")
            var params = {
                PAGEINDEX: this.PageIndex,
                PAGESIZE: this.PageSize,
                NAME: this.name,
                TOOLBRAND: this.njBarnd,
                TOOLTYPESNOWID: this.njType,
                CROPTYPESNOWID: this.crop,
                SERVICELINK: this.link,
            }
            this.$http.post("TooL/GetDataList", params)
                .then((response) => {
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.postList = response.data.DATA
                        this.Total = response.data.TOTALCOUNT
                    } else {
                        this.postList = []
                        this.Total = 0
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                    this.hideLoading()
                    this.postList = []
                    this.Total = 0
                });
        },
        searchPost() {
            this.PageIndex = 1
            this.getPostList()
        },
        addPost() {
            this.postInfo = {
                owersnowid: "0",
                name: "",
                SYSUSERSNOWID: "",
                toolSnowID: "",
                tooltypesnowid: "",
                tooltypename: "",
                TOOLBRANDSNOWID: "",
                toolbrand: "",
                croptypesnowid: "",
                croptype: "",
                servicelink: "",
                breadth: "",
                buyyear: "",
                buyyears: "",
                OwerPhone: "",
                OwerName: ""
            }
            this.doTypeStr = "添加农具"
            this.doType = "1"
            this.postModal = true
        },
        sendAdd() {
            this.canDo = false
            this.showLoading("请稍后")
            this.$http.post("TooL/InsertData", this.postInfo)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已添加")
                        this.postModal = false
                        this.getPostList()
                    } else {
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },
        editPost(item) {
            this.postInfo = {
                SnowID: item.SNOWID,
                owersnowid: "0",
                name: item.NAME,
                SYSUSERSNOWID: item.SYSUSERSNOWID,
                toolSnowID: item.TOOLSNOWID,
                tooltypesnowid: item.TOOLTYPESNOWID,
                tooltypename: item.TOOLTYPENAME,
                TOOLBRANDSNOWID: item.TOOLBRANDSNOWID,
                toolbrand: item.TOOLBRAND,
                croptypesnowid: item.CROPTYPESNOWID,
                croptype: item.CROPTYPE,
                servicelink: item.SERVICELINK,
                breadth: item.BREADTH,
                buyyear: item.BUYYEAR,
                buyyears: item.BUYYEAR ? new Date(item.BUYYEAR) : '',
                OwerPhone: item.OWERPHONE,
                OwerName: item.OWERNAME
            }
            this.doTypeStr = "修改农具"
            this.doType = "2"
            this.postModal = true
        },
        sendEdit() {
            this.canDo = false
            this.showLoading("请稍后")
            this.$http.post("TooL/UpdateData", this.postInfo)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已修改")
                        this.postModal = false
                        this.getPostList();
                    } else {
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },
        delPost(item) {
            if (this.canDo) {
                this.$confirm('确定删除：' + item.NAME + '？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.sendDel(item.SNOWID)
                }).catch(() => {
                    this.$message.info("已取消")
                });
            }

        },
        sendDel(id) {
            this.canDo = false
            this.showLoading("请稍后")
            this.$http.post("TooL/DeleteData", { SnowID: id })
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已删除")
                        this.postModal = false
                        this.getPostList()
                    } else {
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },
        PGChange(e) {
            this.PageIndex = e
            this.getPostList()
        },
        submitPostForm() {
            if (this.canDo) {
                this.$refs.uForm.validate((valid) => {
                    if (valid) {
                        if(this.postInfo.buyyears){
                          this.postInfo.buyyear = this.comjs.getDateMinStrByDate(this.postInfo.buyyears)
                        }
                        this.postInfo.servicelink = parseInt(this.postInfo.servicelink)
                        this.postInfo.breadth = parseFloat(this.postInfo.breadth)
                        if (this.doType == "1") {
                            this.sendAdd()
                        } else if (this.doType == "2") {
                            this.sendEdit()
                        }
                    } else {
                        return false;
                    }
                });
            }
        },
    },
    computed: {
    },
    watch: {
    },
};
</script>
<style ></style>