<template>
  <div class="views-main">
    <div class="views-main-title">
      <p class="txt">合同列表</p>
    </div>
    <div class="tableBox" style="top:50px;">
      <div class="views-main-search">
        <el-select v-model="searchInfo.COOPERATIVEUSERSNOWID" placeholder="请选择服务组织" filterable class="iw">
          <el-option v-for="item in serviceOrganizationList" :key="item.SYSUSERSNOWID" :label="item.SYSUSERDISPLAYNAME" :value="item.SYSUSERSNOWID"></el-option>
        </el-select>
        <el-input v-model="searchInfo.contractCode" placeholder="请输入合同编号" class="iw" ></el-input>
        <el-input v-model="searchInfo.farmerName" placeholder="请输入农户名称" class="iw" ></el-input>
        <el-date-picker
            v-model="searchInfo.signDate"
            style="margin-right: 20px"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
        <el-button type="primary" @click="searchPost">查询</el-button>
        <el-button type="success" @click="addPost" class="fr">添加</el-button>
        <el-button @click="exportDocument" v-if="sysType == '1'" type="success" class="fr">导出文档</el-button>
      </div>
      <div class="views-main-body">
        <div style="display: flex;font-size: 14px">
            <div class="topText">托管合同数: <span>{{generalData.ContractCount}}</span> 份</div>
            <div class="topText">托管农户数: <span>{{generalData.FarmerCount}}</span> 户</div>
            <div class="topText">托管面积: <span>{{generalData.AreaCount}}</span> 亩</div>
            <div class="topText">托管金额: <span>{{generalData.Money}}</span> 元   </div>
        </div>
        <el-table :data="postList" @select="select" @select-all="selectAll" stripe style="width: 100%" tooltip-effect="dark">
          <el-table-column
              v-if="sysType == '1'"
              type="selection"
              width="30">
          </el-table-column>
          <el-table-column prop="CooperativeUserName" label="服务组织名称"></el-table-column>
          <el-table-column prop="CONTRACTNO" label="合同编号"></el-table-column>
          <el-table-column prop="FARMERNAME" label="农户名称"></el-table-column>
          <el-table-column prop="IDCARD" label="农户身份证" width="200"></el-table-column>
          <el-table-column prop="PHONE" label="农户电话"></el-table-column>
          <el-table-column prop="LANDCOUT" label="托管地块数量"></el-table-column>
          <el-table-column prop="AREA" label="托管面积(亩)"></el-table-column>
          <el-table-column prop="MONEY" label="托管金额(元)"></el-table-column>
          <el-table-column label="签订时间" width="200">
            <template slot-scope="scope">
              <div>{{scope.row.STARTTIME + '-' + scope.row.ENDTIME}}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
              <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
      </div>
    </div>
    <el-dialog :title="doTypeStr" :visible.sync="postModal" width="600px" :before-close="close">
      <el-form :model="postInfo" hide-required-asterisk :rules="postrules"  ref="uForm" label-width="120px" class="demo-uForm nForm">
        <el-form-item label="服务组织" prop="COOPERATIVEUSERSNOWID">
          <el-select v-model="postInfo.COOPERATIVEUSERSNOWID" placeholder="请选择服务组织" filterable clearable class="iw" style="width: 100% !important;">
            <el-option v-for="item in serviceOrganizationList" :key="item.SYSUSERSNOWID" :label="item.SYSUSERDISPLAYNAME" :value="item.SYSUSERSNOWID"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="合同编号" prop="contractno">
          <el-input placeholder="请输入合同编号" v-model="postInfo.contractno" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="农户姓名" prop="farmername">
          <el-input placeholder="请输入农户姓名" v-model="postInfo.farmername" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="农户身份证" prop="idcard">
          <el-input placeholder="请输入农户身份证" v-model="postInfo.idcard" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="农户电话" prop="phone">
          <el-input placeholder="请输入农户电话" v-model="postInfo.phone" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="托管地块数量" prop="landcout">
          <el-input placeholder="请输入托管地块数量" v-model="postInfo.landcout" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="托管面积" prop="area">
          <el-input placeholder="请输入托管面积" v-model="postInfo.area" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="托管金额" prop="money">
          <el-input placeholder="请输入托管金额" v-model="postInfo.money" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="签订日期" prop="signDate">
          <el-date-picker
              v-model="postInfo.signDate"
              style="width: 100%"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitPostForm()">提交</el-button>
          <el-button @click="postModal = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "ContractList",
  data() {
    var idcard = (rule, value, callback) => {
      let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (value === "") {
        callback(new Error("请输入身份证号码"));
      } else {
        if(reg.test(value) === false){
          callback(new Error("请输入正确的身份证号码"));
        }else{
          callback();
        }
      }
    };
    var phone = (rule, value, callback) => {
      let reg = /^1[3-9][0-9]{9}$/
      if (value === "") {
        callback(new Error("请输入电话号码"));
      } else {
        if(!reg.test(value)){
          callback(new Error("请输入正确的电话号码"));
        }else{
          callback();
        }
      }
    };
    return {
      canDo:true,
      serviceOrganizationList: [], //服务组织数据
      searchInfo: {
        COOPERATIVEUSERSNOWID: '', // 服务组织id
        contractCode: '', // 合同编号
        signDate: '', // 签订时间
        farmerName: '', // 农户名称
      },
      postList:[],
      generalData: {},
      PageIndex:1,
      PageSize:10,
      Total:0,
      doTypeStr:"",
      doType:"",
      postModal:false,
      postInfo:{},
      selectList: [],
      postrules: {
        COOPERATIVEUSERSNOWID: [
          { required: true, message: '请选择服务组织', trigger: 'blur' },
        ],
        contractno: [
          { required: true, message: '请输入合同编号', trigger: 'blur' },
        ],
        farmername: [
          { required: true, message: '请输入农户姓名', trigger: 'blur' },
        ],
        idcard: [
          { validator: idcard, trigger: 'blur' }
        ],
        phone: [
          { validator: phone, trigger: 'blur' }
        ],
        landcout: [
          { required: true, message: '请输入托管地块数量', trigger: 'blur' },
        ],
        area: [
          { required: true, message: '请输入托管面积', trigger: 'blur' },
        ],
        money: [
          { required: true, message: '请输入托管金额', trigger: 'blur' },
        ],
        signDate: [
          { required: true, message: '请选择签订时间', trigger: 'blur' },
        ]
      },
      sysType: ''
    };
  },
  mounted() {
    this.sysType = this.$store.state.sysType
    if(this.sysType =="2"){
      this.regId= this.$store.state.xzqSnowid
    }
    this.loadItem = null
    this.getServiceOrganizationList()
    this.getPostList()
  },
  methods: {
    // 获取服务组织列表数据
    getServiceOrganizationList(){
      var params={}
      this.$http.post("CooperativeUser/GetDataList", params)
          .then((response) => {
            if (response.data.SUCCESS) {
              this.serviceOrganizationList = response.data.DATA
              // this.CooperativeUserSnowID = this.serviceOrganizationList[0]
            } else {
              this.$message.error(response.data.MSG)
            }
          })
          .catch((error) => {
            this.errorFlag(JSON.stringify(error))
          });
    },
    getPostList(){
      this.showLoading("请稍后")
      var params={
        pageIndex:this.PageIndex,
        pageSize:this.PageSize,
        FarmerName: this.searchInfo.farmerName,
        ContractNO: this.searchInfo.contractCode,
        CooperativeUserSnowID: this.searchInfo.COOPERATIVEUSERSNOWID,
        StartTime: '',
        EndTime: '',
      }
      if(Array.isArray(this.searchInfo.signDate) && this.searchInfo.signDate.length > 0){
        params.StartTime = this.searchInfo.signDate[0]
        params.EndTime = this.searchInfo.signDate[1]
      }
      this.getGeneralData(params.CooperativeUserSnowID,params.StartTime,params.EndTime)
      this.$http.post("FarmerContract/GetDataList", params)
          .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.postList=response.data.DATA
              this.Total=response.data.TOTALCOUNT
            } else {
              this.postList=[]
              this.Total=0
              this.$message.error(response.data.MSG)
            }
          })
          .catch((error) => {
            this.errorFlag(JSON.stringify(error))
            this.hideLoading()
            this.postList=[]
            this.Total=0
          });
    },
    // 获取总的数据信息
    getGeneralData(CooperativeUserSnowID,StartTime,EndTime){
      let params = {
        CooperativeUserSysSnowID: CooperativeUserSnowID,
        StartTime,
        EndTime,
        // RegionUserSysSnowID: ''
      }
      if(this.$store.state.sysType == "2"){
        params.RegionUserSysSnowID= this.$store.state.xzqSnowid
      }
      this.$http.post("Statistics/GetContractStatisics", params)
          .then((response) => {
            if (response.data.SUCCESS) {
              this.generalData = response.data.DATA
            } else {
              this.$message.error(response.data.MSG)
            }
          })
          .catch((error) => {
            this.errorFlag(JSON.stringify(error))
          });
    },
    searchPost(){
      this.PageIndex=1
      this.getPostList()
    },
    addPost(){
      this.postInfo={
        COOPERATIVEUSERSNOWID: '',
        contractno: '',
        farmername: '',
        idcard: '',
        phone: '',
        landcout: '',
        area: '',
        money: '',
        starttime: '',
        endtime: ''
      }
      this.doTypeStr="添加合同"
      this.doType="1"
      this.postModal=true
    },
    sendAdd(){
      this.postInfo.starttime = this.postInfo.signDate[0]
      this.postInfo.endtime = this.postInfo.signDate[1]
      this.canDo=false
      this.showLoading("请稍后")
      this.$http.post("FarmerContract/InsertData", this.postInfo)
          .then((response) => {
            this.canDo=true
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.$message.success("已添加")
              this.postModal=false
              this.getPostList()
            } else {
              this.$message.error(response.data.MSG)
            }
          })
          .catch((error) => {
            this.errorFlag(JSON.stringify(error))
            this.$message.error("请求出错")
            this.hideLoading()
            this.canDo=true
          });
    },
    editPost(item){
      this.postInfo={
        SnowID: item.SNOWID,
        COOPERATIVEUSERSNOWID: item.COOPERATIVEUSERSNOWID,
        contractno: item.CONTRACTNO,
        farmername: item.FARMERNAME,
        idcard: item.IDCARD,
        phone: item.PHONE,
        landcout: item.LANDCOUT,
        area: item.AREA,
        money: item.MONEY,
        starttime: item.STARTTIME,
        endtime: item.ENDTIME
      }
      this.postInfo.signDate = [item.STARTTIME,item.ENDTIME]
      this.doTypeStr="修改合同"
      this.doType="2"
      this.postModal=true
    },
    sendEdit(){
      this.canDo=false
      this.showLoading("请稍后")
      this.$http.post("FarmerContract/UpdateData", this.postInfo)
          .then((response) => {
            this.canDo=true
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.$message.success("已修改")
              this.postModal=false
              this.getPostList();
            } else {
              this.$message.error(response.data.MSG)
            }
          })
          .catch((error) => {
            this.errorFlag(JSON.stringify(error))
            this.$message.error("请求出错")
            this.hideLoading()
            this.canDo=true
          });
    },
    close(){
      this.postModal = false
      this.$refs.uForm.resetFields()
      this.postInfo = {}
    },
    delPost(item){
      if(this.canDo){
        this.$confirm('确定删除' + '？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.sendDel(item.SNOWID)
        }).catch(() => {
          // this.$message.info("已取消")
        });
      }

    },
    sendDel(id){
      this.canDo=false
      this.showLoading("请稍后")
      this.$http.post("FarmerContract/DeleteData", {SnowID:id})
          .then((response) => {
            this.canDo=true
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.$message.success("已删除")
              this.postModal=false
              this.getPostList()
            } else {
              this.$message.error(response.data.MSG)
            }
          })
          .catch((error) => {
            this.errorFlag(JSON.stringify(error))
            this.$message.error("请求出错")
            this.hideLoading()
            this.canDo=true
          });
    },
    PGChange(e){
      this.PageIndex=e
      this.getPostList()
    },
    submitPostForm() {
      if (this.canDo) {
        this.$refs.uForm.validate((valid) => {
          if (valid) {
            if(this.doType=="1"){
              this.sendAdd()
            }else if(this.doType=="2"){
              this.sendEdit()
            }
          } else {
            return false;
          }
        });
      }
    },
    select(node){
      console.log(node)
      this.selectList = node
    },
    selectAll(node){
      console.log(node)
      this.selectList = node
    },
    // 导出文档
    exportDocument(){
      if(this.selectList.length > 0){
        this.showLoading("请稍后")
        this.selectList.forEach((item)=> {
          let params = {
            CooperativeUserSnowID: item.COOPERATIVEUSERSNOWID,
            StartTime: '',
            EndTime: ''
          }
          this.$download.post("WorkReport/ExportFarmerContractList", params, {responseType: 'blob', timeout: 300000})
              .then((resJson) => {
                this.hideLoading()
                this.canDo = true
                if (resJson) {
                  var name = "合同报告"
                  let blob = new Blob([resJson.data], {type: 'application/octet-stream'})
                  if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveBlob(blob, name + ".xls")
                  } else {
                    let downloadElement = document.createElement('a')
                    let href = window.URL.createObjectURL(blob) //创建下载的链接
                    downloadElement.href = href
                    downloadElement.download = name + ".xls"
                    document.body.appendChild(downloadElement)
                    //此写法兼容火狐
                    let evt = document.createEvent('MouseEvents')
                    evt.initEvent('click', false, false)
                    downloadElement.dispatchEvent(evt)
                    document.body.removeChild(downloadElement)
                  }
                  this.$message.success('操作成功!')
                } else {
                  this.$message.error("数据导出失败")
                }

              })
        })
      }else{
        this.$message.error('请选择要导出的数据!')
      }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style>
.nForm .el-form-item.el-form-item--feedback > .el-form-item__label:before {
  content: '*';
  color: #F56C6C;
  margin-right: 4px;
}
.topText{
  margin-right: 10px;
  color: grey;
  font-weight: 700;
}
.topText > span{
  color: red;
}
</style>