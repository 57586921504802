<template>
  <div class="views-main">
    <div class="views-main-title">
      <p class="txt">服务组织备案管理</p>
    </div>
    <div class="tableBox" style="top:50px;">
      <div class="views-main-search">
        <el-select v-model="searchInfo.COOPERATIVEUSERSNOWID" placeholder="请选择服务组织" filterable clearable class="iw">
          <el-option v-for="item in serviceOrganizationList" :key="item.SYSUSERSNOWID" :label="item.SYSUSERDISPLAYNAME" :value="item.SYSUSERSNOWID"></el-option>
        </el-select>
        <el-button type="primary" @click="searchPost">查询</el-button>
        <el-button type="success" @click="addPost" class="fr">添加</el-button>
      </div>
      <div class="views-main-body">
        <el-table :data="postList" @select="select" @select-all="selectAll" stripe style="width: 100%">
          <el-table-column prop="CooperativeUserName" label="服务组织名称"></el-table-column>
          <el-table-column prop="RegionName" label="服务区域"></el-table-column>
          <el-table-column prop="NAME" label="备案名称"></el-table-column>
          <el-table-column prop="TASKAREA" label="任务面积（亩）"></el-table-column>
          <el-table-column prop="WORKAREA" label="计划面积（亩）"></el-table-column>
          <el-table-column prop="MONEY" label="补贴金额"></el-table-column>
          <el-table-column prop="BZ" label="备注"></el-table-column>
          <el-table-column prop="STARTTIME" label="开始时间"></el-table-column>
          <el-table-column prop="ENDTIME" label="结束时间"></el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
              <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
      </div>
    </div>
    <el-dialog :title="doTypeStr" :visible.sync="postModal" width="600px" :before-close="close">
      <el-form :model="postInfo" hide-required-asterisk :rules="postrules"  ref="uForm" label-width="140px" class="demo-uForm nForm">
        <el-form-item label="服务组织" prop="cooperativeusersnowid">
          <el-select v-model="postInfo.cooperativeusersnowid" placeholder="请选择服务组织" filterable clearable class="iw" style="width: 100% !important;">
            <el-option v-for="item in serviceOrganizationList" :key="item.SYSUSERSNOWID" :label="item.SYSUSERDISPLAYNAME" :value="item.SYSUSERSNOWID"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目" prop="projectsnowid">
          <el-select v-model="postInfo.projectsnowid" placeholder="请选择项目" filterable clearable class="iw" style="width: 100% !important;">
            <el-option v-for="item in projectData" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备案名称" prop="name">
          <el-input placeholder="请输入备案名称" v-model="postInfo.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="任务面积（亩）" prop="taskarea">
          <el-input placeholder="请输入任务面积" v-model="postInfo.taskarea" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="计划面积（亩）" prop="workare">
          <el-input placeholder="请输入计划面积" v-model="postInfo.workarea" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="补贴金额" prop="money">
          <el-input placeholder="请输入补贴金额" v-model="postInfo.money" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="bz">
          <el-input placeholder="请输入备注" v-model="postInfo.bz" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="日期" prop="signDate">
          <el-date-picker
              v-model="postInfo.signDate"
              style="width: 100%"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitPostForm()">提交</el-button>
          <el-button @click="postModal = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "ServeTeamManage",
  data() {
    return {
      hideRequiredAsterisk: true,
      canDo:true,
      serviceOrganizationList: [], //服务组织数据
      searchInfo: {
        COOPERATIVEUSERSNOWID: '', // 服务组织id
        contractCode: '', // 合同编号
        signDate: '', // 签订时间
        farmerName: '', // 农户名称
      },
      postList:[],
      projectData: [],
      generalData: {},
      PageIndex:1,
      PageSize:10,
      Total:0,
      doTypeStr:"",
      doType:"",
      postModal:false,
      postInfo:{},
      selectList: [],
      postrules: {
        cooperativeusersnowid: [
          { required: true, message: '请选择服务组织', trigger: 'blur' },
        ],
        projectsnowid: [
          { required: true, message: '请选择项目', trigger: 'blur' },
        ],
        name: [
          { required: true, message: '请输入备案名称', trigger: 'blur' },
        ],
        taskarea: [
          { required: true, message: '请输入任务面积', trigger: 'blur' },
        ],
        workarea: [
          { required: true, message: '请输入计划面积', trigger: 'blur' },
        ],
        money: [
          { required: true, message: '请输入补贴金额', trigger: 'blur' },
        ],
        bz: [
          { required: true, message: '请输入备注', trigger: 'blur' },
        ],
        signDate: [
          { required: true, message: '请选择时间', trigger: 'blur' },
        ]
      },
      sysType: ''
    };
  },
  mounted() {
    this.sysType = this.$store.state.sysType
    if(this.sysType =="2"){
      this.regId= this.$store.state.xzqSnowid
    }
    this.loadItem = null
    this.getServiceOrganizationList()
    this.getPostList()
    this.getProjectData()
  },
  methods: {
    // 获取项目数据
    getProjectData(){
      var params={
        pageIndex: 0,
        pageSize: 0
      }
      if(this.sysType == 2){
        params.REGIONSNOWID = this.regId
      }
      this.$http.post("Project/GetDataList", params)
          .then((response) => {
            if (response.data.SUCCESS) {
              this.projectData = response.data.DATA
            } else {
              this.$message.error(response.data.MSG)
            }
          })
          .catch((error) => {
            this.errorFlag(JSON.stringify(error))
          });
    },
    // 获取服务组织列表数据
    getServiceOrganizationList(){
      var params={}
      this.$http.post("CooperativeUser/GetDataList", params)
          .then((response) => {
            if (response.data.SUCCESS) {
              this.serviceOrganizationList = response.data.DATA
            } else {
              this.$message.error(response.data.MSG)
            }
          })
          .catch((error) => {
            this.errorFlag(JSON.stringify(error))
          });
    },
    getPostList(){
      this.showLoading("请稍后")
      var params={
        pageIndex:this.PageIndex,
        pageSize:this.PageSize,
        CooperativeUserSnowID: this.searchInfo.COOPERATIVEUSERSNOWID,
      }
      // if(this.sysType == 2){
      //   params.RegionSnowID = this.regId
      // }
      this.$http.post("CooperativeFilings/GetDataList", params)
          .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.postList=response.data.DATA
              this.Total=response.data.TOTALCOUNT
            } else {
              this.postList=[]
              this.Total=0
              this.$message.error(response.data.MSG)
            }
          })
          .catch((error) => {
            this.errorFlag(JSON.stringify(error))
            this.hideLoading()
            this.postList=[]
            this.Total=0
          });
    },
    // 获取总的数据信息
    getGeneralData(CooperativeUserSnowID,StartTime,EndTime){
      let params = {
        CooperativeUserSnowID,
        StartTime,
        EndTime
      }
      this.$http.post("Statistics/GetContractStatisics", params)
          .then((response) => {
            if (response.data.SUCCESS) {
              this.generalData = response.data.DATA
            } else {
              this.$message.error(response.data.MSG)
            }
          })
          .catch((error) => {
            this.errorFlag(JSON.stringify(error))
          });
    },
    searchPost(){
      this.PageIndex=1
      this.getPostList()
    },
    addPost(){
      this.postInfo={
        name: '',
        cooperativeusersnowid: '',
        projectsnowid: '',
        starttime: '',
        endtime: '',
        taskarea: '',
        workarea: '',
        money: '',
        bz: ''
      }
      this.doTypeStr="添加备案"
      this.doType="1"
      this.postModal=true
    },
    sendAdd(){
      this.postInfo.starttime = this.postInfo.signDate[0]
      this.postInfo.endtime = this.postInfo.signDate[1]
      this.canDo=false
      this.showLoading("请稍后")
      this.$http.post("CooperativeFilings/InsertData", this.postInfo)
          .then((response) => {
            this.canDo=true
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.$message.success("已添加")
              this.postModal=false
              this.getPostList()
            } else {
              this.$message.error(response.data.MSG)
            }
          })
          .catch((error) => {
            this.errorFlag(JSON.stringify(error))
            this.$message.error("请求出错")
            this.hideLoading()
            this.canDo=true
          });
    },
    editPost(item){
      this.postInfo={
        SnowID: item.SNOWID,
        name: item.NAME,
        cooperativeusersnowid: item.COOPERATIVEUSERSNOWID,
        projectsnowid: item.PROJECTSNOWID,
        starttime: item.STARTTIME,
        endtime: item.ENDTIME,
        taskarea: item.TASKAREA,
        workarea: item.WORKAREA,
        money: item.MONEY,
        bz: item.BZ,
        signDate: []
      }
      this.postInfo.signDate[0] = item.STARTTIME
      this.postInfo.signDate[1] = item.ENDTIME
      this.doTypeStr="修改备案"
      this.doType="2"
      this.postModal=true
    },
    sendEdit(){
      this.canDo=false
      this.showLoading("请稍后")
      this.postInfo.starttime = this.postInfo.signDate[0]
      this.postInfo.endtime = this.postInfo.signDate[1]
      this.$http.post("CooperativeFilings/UpdateData", this.postInfo)
          .then((response) => {
            this.canDo=true
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.$message.success("已修改")
              this.postModal=false
              this.getPostList();
            } else {
              this.$message.error(response.data.MSG)
            }
          })
          .catch((error) => {
            this.errorFlag(JSON.stringify(error))
            this.$message.error("请求出错")
            this.hideLoading()
            this.canDo=true
          });
    },
    close(){
      this.postModal = false
      this.$refs.uForm.resetFields()
      this.postInfo = {}
    },
    delPost(item){
      if(this.canDo){
        this.$confirm('确定删除' + '？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.sendDel(item.SNOWID)
        }).catch(() => {
          // this.$message.info("已取消")
        });
      }

    },
    sendDel(id){
      this.canDo=false
      this.showLoading("请稍后")
      this.$http.post("CooperativeFilings/DeleteData", {SnowID:id})
          .then((response) => {
            this.canDo=true
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.$message.success("已删除")
              this.postModal=false
              this.getPostList()
            } else {
              this.$message.error(response.data.MSG)
            }
          })
          .catch((error) => {
            this.errorFlag(JSON.stringify(error))
            this.$message.error("请求出错")
            this.hideLoading()
            this.canDo=true
          });
    },
    PGChange(e){
      this.PageIndex=e
      this.getPostList()
    },
    submitPostForm() {
      if (this.canDo) {
        this.$refs.uForm.validate((valid) => {
          if (valid) {
            if(this.doType=="1"){
              this.sendAdd()
            }else if(this.doType=="2"){
              this.sendEdit()
            }
          } else {
            return false;
          }
        });
      }
    },
    select(node){
      this.selectList = node
    },
    selectAll(node){
      this.selectList = node
    },
    // 导出文档
    exportDocument(){
      if(this.selectList.length > 0){
        this.showLoading("请稍后")
        this.selectList.forEach((item)=> {
          let params = {
            CooperativeUserSnowID: item.COOPERATIVEUSERSNOWID,
            StartTime: '',
            EndTime: ''
          }
          this.$download.post("WorkReport/ExportFarmerContractList", params, {responseType: 'blob', timeout: 300000})
              .then((resJson) => {
                this.hideLoading()
                this.canDo = true
                if (resJson) {
                  var name = "合同报告"
                  let blob = new Blob([resJson.data], {type: 'application/octet-stream'})
                  if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveBlob(blob, name + ".xls")
                  } else {
                    let downloadElement = document.createElement('a')
                    let href = window.URL.createObjectURL(blob) //创建下载的链接
                    downloadElement.href = href
                    downloadElement.download = name + ".xls"
                    document.body.appendChild(downloadElement)
                    //此写法兼容火狐
                    let evt = document.createEvent('MouseEvents')
                    evt.initEvent('click', false, false)
                    downloadElement.dispatchEvent(evt)
                    document.body.removeChild(downloadElement)
                  }
                  this.$message.success('操作成功!')
                } else {
                  this.$message.error("数据导出失败")
                }

              })
        })
      }else{
        this.$message.error('请选择要导出的数据!')
      }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style>
.nForm .el-form-item.el-form-item--feedback > .el-form-item__label:before {
  content: '*';
  color: #F56C6C;
  margin-right: 4px;
}
.topText{
  margin-right: 10px;
  color: grey;
  font-weight: 700;
}
.topText > span{
  color: red;
}
</style>