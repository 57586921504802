<template>
  <div class="views-main">
    <div class="views-main-title">
      <p class="txt" style="text-align: center;margin:200px;width:70%;">暂无数据!</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContractStatistics"
}
</script>

<style scoped>

</style>