<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">定位器管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">

                <el-input v-model="name" placeholder="请输入名称" class="iw"></el-input>
                <el-input v-model="imei" placeholder="请输入IMEI" class="iw"></el-input>
                <el-select v-model="isBind" placeholder="是否绑定农机" filterable clearable class="iw">
                    <el-option label="全部绑定状态" value=""></el-option>
                    <el-option label="已绑定" value="1"></el-option>
                    <el-option label="未绑定" value="0"></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button  type="success" @click="addPost" class="fr">添加</el-button>
                <!-- v-if="sysType == 1" -->
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%">
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="IMEI" label="IMEI"></el-table-column>
                    <el-table-column prop="EXPIRETIME" label="过期时间"></el-table-column>
                    <el-table-column prop="SYSUSERNAME" label="服务组织用户"></el-table-column>
                    <el-table-column prop="MECHINERYNAME" label="已绑农机">
                        <template slot-scope="scope">
                            <span v-if="scope.row.MECHINERYNAME">{{ scope.row.MECHINERYNAME }}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="MIL" label="里程"></el-table-column>
                    <el-table-column prop="SPEED" label="时速"></el-table-column>
                    <el-table-column prop="ISLOGIN" label="在线状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ISLOGIN == 1" style="color:#67C23A">在线</span>
                            <span v-else style="color:#F56C6C">离线</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="GPSTIME" label="上次在线时间">
                        <template slot-scope="scope">
                            <span v-if="scope.row.GPSTIME != '1900-01-01 00:00:00'">{{ scope.row.GPSTIME }}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100">
                        <!-- v-if="sysType == '1'" -->
                        <template slot-scope="scope">
                            <el-button  @click="editPost(scope.row)" type="text" size="small"
                                style="color:#E6A23C">编辑</el-button>
                            <el-button  @click="delPost(scope.row)" type="text" size="small"
                                style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next" :current-page="PageIndex" :page-size="PageSize"
                    :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="900px" top="30px" :close-on-click-modal="false">
            <el-form :model="postInfo" status-icon :rules="postrules" ref="uForm" label-width="150px" class="demo-uForm">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="postInfo.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="IMEI" prop="imei">
                    <el-input v-model="postInfo.imei" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="过期时间" prop="EXPIRETIMES">
                    <el-date-picker v-model="postInfo.EXPIRETIMES" type="datetime" placeholder="选择过期时间" style="width:100%;">
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="服务组织用户">
                    <el-select v-model="postInfo.sysusersnowid" style="width:100%;" clearable>
                        <el-option v-for="item in teamList" :key="item.SYSUSERSNOWID" :label="item.DisplayName"
                            :value="item.SYSUSERSNOWID"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="绑定农机">
                    <el-select v-model="postInfo.mechinerysnowid" style="width:100%;" clearable>
                        <el-option v-for="item in mechineList" :key="item.SnowID" :label="item.NAME"
                            :value="item.SnowID"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: "name",
    data() {
        var name = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入名称"));
            } else {
                callback();
            }
        };
        var imei = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入IMEI值"));
            } else {
                callback();
            }
        };
        var EXPIRETIMES= (rule, value, callback) => {
            if (!value||value === "") {
                callback(new Error("请选择过期时间"));
            } else {
                callback();
            }
        };
        return {
            canDo: true,

            sysType: "1",
            regId: "",
            regUserId: "",

            isBind: "",
            name: "",
            imei: "",
            teamList: [],
            mechineList: [],

            postList: [],
            PageIndex: 1,
            PageSize: 10,
            Total: 0,


            doTypeStr: "",
            doType: "",
            postModal: false,
            postInfo: {},
            postrules: {
                name: [{ validator: name, trigger: "blur" }],
                imei: [{ validator: imei, trigger: "blur" }],
                EXPIRETIMES: [{ validator: EXPIRETIMES, trigger: "blur" }],
            },
        };
    },
    mounted() {
        this.sysType = this.$store.state.sysType
        if (this.sysType == "2") {
            this.regId = this.$store.state.xzqSnowid
            this.regUserId = this.$store.state.xzqUserSnowid
        }
        this.loadItem = null
        this.getTeamList()
        this.getMechineList()
        this.getPostList()
    },
    methods: {
        getTeamList() {
            this.$http.post("CooperativeUser/GetDataList", {})
                .then((response) => {
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.teamList = response.data.DATA
                    }
                }).catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                });
        },
        getMechineList() {
            this.$http.post("Mechinery/GetDataList", {})
                .then((response) => {
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.mechineList = response.data.DATA
                    }
                }).catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                });
        },
        getPostList() {
            this.showLoading("请稍后")
            var params = {
                PAGEINDEX: this.PageIndex,
                PAGESIZE: this.PageSize,
                NAME: this.name,
                IMEI: this.imei,
                IsBand: parseInt(this.isBind),
            }
            // if(this.sysType == 2){
            //   params.regionSnowID = this.regId
            // }
            this.$http.post("Positioner/GetDataList", params)
                .then((response) => {
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.postList = response.data.DATA
                        this.Total = response.data.TOTALCOUNT
                    } else {
                        this.postList = []
                        this.Total = 0
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                    this.hideLoading()
                    this.postList = []
                    this.Total = 0
                });
        },
        searchPost() {
            this.PageIndex = 1
            this.getPostList()
        },
        addPost() {
            this.postInfo = {
                name: "",
                imei: "",
                mechinerysnowid: "",
                sysusersnowid: "",
                EXPIRETIMES:null,
            }
            this.doTypeStr = "添加定位器"
            this.doType = "1"
            this.postModal = true
        },
        sendAdd() {
            this.canDo = false
            this.showLoading("请稍后")
            this.$http.post("Positioner/InsertData", this.postInfo)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已添加")
                        this.postModal = false
                        this.getPostList()
                    } else {
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },
        editPost(item) {
            this.postInfo = {
                SnowID: item.SNOWID,
                name: item.NAME,
                imei: item.IMEI,
                mechinerysnowid: item.MECHINERYSNOWID,
                sysusersnowid: item.SYSUSERSNOWID,
                EXPIRETIMES:new Date(item.EXPIRETIME)
            }
            this.doTypeStr = "修改定位器"
            this.doType = "2"
            this.postModal = true
        },
        sendEdit() {
            this.canDo = false
            this.showLoading("请稍后")
            this.$http.post("Positioner/UpdateData", this.postInfo)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已修改")
                        this.postModal = false
                        this.getPostList();
                    } else {
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },
        delPost(item) {
            if (this.canDo) {
                this.$confirm('确定删除：' + item.NAME + '？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.sendDel(item.SNOWID)
                }).catch(() => {
                    this.$message.info("已取消")
                });
            }

        },
        sendDel(id) {
            this.canDo = false
            this.showLoading("请稍后")
            this.$http.post("Positioner/DeleteData", { SnowID: id })
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已删除")
                        this.postModal = false
                        this.getPostList()
                    } else {
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },
        PGChange(e) {
            this.PageIndex = e
            this.getPostList()
        },
        submitPostForm() {
            if (this.canDo) {
                this.$refs.uForm.validate((valid) => {
                    if (valid) {
                        this.postInfo.EXPIRETIME = this.comjs.getDateStrByDate(this.postInfo.EXPIRETIMES)
                        if (this.doType == "1") {
                            this.sendAdd()
                        } else if (this.doType == "2") {
                            this.sendEdit()
                        }
                    } else {
                        return false;
                    }
                });
            }
        },
    },
    computed: {
    },
    watch: {
    },
};
</script>
<style ></style>