import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

//管理员
import AdminUserManage from '../views/AdminPages/AdminUserManage.vue'
//行政区
import AdminRegionManage from '../views/AdminPages/AdminRegionManage.vue'
//项目
import ProjectManage from '../views/AdminPages/ProjectManage.vue'
//服务组织
import ServeTeamManage from '../views/AdminPages/ServeTeamManage.vue'
// 服务组织备案
import ServiceOrganizationFiling from '../views/AdminPages/ServiceOrganizationFiling.vue'
//字典项
import ServeTeamType from '../views/DictionaryItem/ServeTeamType.vue'
import MachineType from '../views/DictionaryItem/MachineType.vue'
import MachineBrand from '../views/DictionaryItem/MachineBrand.vue'
import ToolsType from '../views/DictionaryItem/ToolsType.vue'
import ToolsBrand from '../views/DictionaryItem/ToolsBrand.vue'
import CropsType from '../views/DictionaryItem/CropsType.vue'
import HandleType from '../views/DictionaryItem/HandleType.vue'
// 合同列表
import ContractList from '../views/EscrowContractItem/ContractList.vue'
// 作业列表
import OperationList from '../views/OperationProcess/OperationList.vue'
// 作业地图
import OperationMap from '../views/OperationProcess/OperationMap.vue'
// 作业统计
import OperationStatistics from '../views/OperationProcess/OperationStatistics.vue'
// 质量监控
import QualityControl from '../views/DataQuality/QualityControl.vue'
// 服务组织合同汇总统计
import ContractStatistics from '../views/SummaryStatistics/ContractStatistics.vue'
//农机
import MachineManage from '../views/Device/MachineManage.vue'
//农具
import ToolManage from '../views/Device/ToolManage.vue'
//定位器管理
import IMEIManage from '../views/Device/IMEIManage.vue'
//检测报告管理
import ExaminingReportManage from '../views/SummaryStatistics/ExaminingReportManage.vue'

import store from '../store/index'
import cookies from 'vue-cookies'
Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/AdminLogin',
    name: 'AdminLogin',
    component: Login
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/AdminUserManage',
        name: 'AdminUserManage',
        components: {AdminUserManage}
      },
      {
        path: '/AdminRegionManage',
        name: 'AdminRegionManage',
        components: {AdminRegionManage}
      },
      {
        path: '/ProjectManage',
        name: 'ProjectManage',
        components: {ProjectManage}
      },
      {
        path: '/ServeTeamManage',
        name: 'ServeTeamManage',
        components: {ServeTeamManage}
      },
      {
        path: '/ServiceOrganizationFiling',
        name: 'ServiceOrganizationFiling',
        components: {ServiceOrganizationFiling}
      },
      {
        path: '/ServeTeamType',
        name: 'ServeTeamType',
        components: {ServeTeamType}
      },
      {
        path: '/MachineType',
        name: 'MachineType',
        components: {MachineType}
      },
      {
        path: '/MachineBrand',
        name: 'MachineBrand',
        components: {MachineBrand}
      },
      {
        path: '/ToolsType',
        name: 'ToolsType',
        components: {ToolsType}
      },
      {
        path: '/ToolsBrand',
        name: 'ToolsBrand',
        components: {ToolsBrand}
      },
      {
        path: '/CropsType',
        name: 'CropsType',
        components: {CropsType}
      },
      {
        path: '/HandleType',
        name: 'HandleType',
        components: {HandleType}
      },
      {
        path: '/MachineManage',
        name: 'MachineManage',
        components: {MachineManage}
      },
      {
        path: '/ToolManage',
        name: 'ToolManage',
        components: {ToolManage}
      },
      {
        path: '/IMEIManage',
        name: 'IMEIManage',
        components: {IMEIManage}
      },
      {
        path: '/ContractList',
        name: 'ContractList',
        components: {ContractList}
      },
      {
        path: '/OperationList',
        name: 'OperationList',
        components: {OperationList}
      },
      {
        path: '/OperationMap',
        name: 'OperationMap',
        components: {OperationMap}
      },
      {
        path: '/OperationStatistics',
        name: 'OperationStatistics',
        components: {OperationStatistics}
      },
      {
        path: '/QualityControl',
        name: 'QualityControl',
        components: {QualityControl}
      },
      {
        path: '/ContractStatistics',
        name: 'ContractStatistics',
        components: {ContractStatistics}
      },
      {
        path: '/ExaminingReportManage',
        name: 'ExaminingReportManage',
        components: {ExaminingReportManage}
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  //console.log("执行路由")
  var initRouter= store.state.initRouter
  if(initRouter == "" && to.name !== 'AdminLogin'){
    if(to.name!= "Login"){
      store.commit('setOpenPage', to.name);
    }
    store.commit('setInitRouter', "1");
    router.replace("/")
  }else{
    next();
  }
});

router.afterEach((to) => {
  //////console.log(to)
});
export default router
