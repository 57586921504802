<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">项目管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">

                <el-input v-model="name" placeholder="请输入名称" class="iw"></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr" v-if="sysType == '1'">添加</el-button>

            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%">
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="REGIONNAME" label="区域"></el-table-column>
                    <el-table-column prop="TASKAREA" label="任务面积"></el-table-column>
                    <el-table-column prop="JOBAREA" label="作业面积"></el-table-column>
                    <el-table-column prop="SUBSIDYMONEY" label="补贴金额"></el-table-column>
                    <el-table-column prop="STARTTIME" label="开始时间"></el-table-column>
                    <el-table-column prop="ENDTIME" label="结束时间"></el-table-column>
                    <el-table-column prop="SIGNTIME" label="签订时间"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="100" v-if="sysType == '1'">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small"
                                style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)" type="text" size="small"
                                style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next" :current-page="PageIndex" :page-size="PageSize"
                    :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="800px" :close-on-click-modal="false">
            <el-form :model="postInfo" status-icon :rules="postrules" ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="postInfo.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="区域" prop="regionsnowid">
<!--                    <el-select v-model="postInfo.regionsnowid" placeholder="选择区域" filterable style="width:100%">-->
<!--                        <el-option v-for="item in regList" :key="item.SNOWID" :label="item.NAME"-->
<!--                            :value="item.SNOWID"></el-option>-->
<!--                    </el-select>-->
                    <el-cascader
                        v-model="postInfo.regionsnowid"
                        :options="cascadeTreeData"
                        :props="cascadeProps"
                        filterable
                        ref="cascader"
                        @change="handleChange"
                        style="width: 100%"></el-cascader>
                </el-form-item>

                <el-form-item label="任务面积" prop="taskarea">
                    <el-input v-model="postInfo.taskarea" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="作业面积" prop="jobarea">
                    <el-input v-model="postInfo.jobarea" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="补贴金额" prop="subsidymoney">
                    <el-input v-model="postInfo.subsidymoney" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="起止时间" prop="time">
                    <el-date-picker v-model="postInfo.time" type="datetimerange" 
                        range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" align="right"
                        style="width:100%">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="签订时间" prop="signtime">
                    <el-date-picker v-model="postInfo.signtime" type="datetime" placeholder="选择签订时间" align="right"
                         style="width:100%">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: "name",
    data() {
        var name = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入名称"));
            } else {
                callback();
            }
        };
        var regionsnowid = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请选择区域"));
            } else {
                callback();
            }
        };
        var taskarea = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入任务面积"));
            } else if (typeof value != 'number' && isNaN(value)) {
                callback(new Error('面积只能是数字'));
            } else if (value <= 0) {
                callback(new Error('面积必须大于0'));
            } else {
                callback();
            }
        };
        var jobarea = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入作业面积"));
            } else if (typeof value != 'number' && isNaN(value)) {
                callback(new Error('面积只能是数字'));
            } else if (value <= 0) {
                callback(new Error('面积必须大于0'));
            } else {
                callback();
            }
        };
        var subsidymoney = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入补贴金额"));
            } else if (typeof value != 'number' && isNaN(value)) {
                callback(new Error('金额只能是数字'));
            } else if (value <= 0) {
                callback(new Error('金额必须大于0'));
            } else {
                callback();
            }
        };
        var time = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请选择起止"));
            } else {
                callback();
            }
        };
        var signtime = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请选择签订时间"));
            } else {
                callback();
            }
        };
        return {
            sysType: "1",
            regId:"",

            canDo: true,

            name: "",

            regList: [],

            postList: [],
            PageIndex: 1,
            PageSize: 10,
            Total: 0,


            doTypeStr: "",
            doType: "",
            postModal: false,
            postInfo: {},
            postrules: {
                name: [{ validator: name, trigger: "blur" }],
                regionsnowid: [{ validator: regionsnowid, trigger: "blur" }],
                taskarea: [{ validator: taskarea, trigger: "blur" }],
                jobarea: [{ validator: jobarea, trigger: "blur" }],
                subsidymoney: [{ validator: subsidymoney, trigger: "blur" }],
                time: [{ validator: time, trigger: "blur" }],
                signtime: [{ validator: signtime, trigger: "blur" }],
            },
            cascadeTreeData: [],

            cascadeProps: {
              value: 'SNOWID',
              label: 'NAME',
              children: 'Child',
              checkStrictly: true,
            },
        };
    },
    mounted() {
        this.sysType = this.$store.state.sysType
        if(this.sysType =="2"){
            this.regId= this.$store.state.xzqSnowid
        }
        this.loadItem = null
        this.getRegList()
        this.getPostList()
    },
    methods: {
        getRegList() {
            var params = {}
            this.$http.post("Region/GetDataTree", params)
                .then((response) => {
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        // this.regList = response.data.DATA
                        let list = JSON.parse(JSON.stringify(response.data.DATA))
                        let arr = this.getTreeData(list)
                        this.cascadeTreeData = arr
                    }else{
                      this.cascadeTreeData = []
                    }
                })
        },
        getTreeData(list){
          // for(let i = 0; i < list.length; i++){
          //   let ele = list[i]
          //   if(ele.REGIONTYPE < 3){
          //     ele.Child = this.getTreeData(ele.Child)
          //   }else if(ele.REGIONTYPE === 3){
          //     let index = list.findIndex((node)=> node.CODE === ele.CODE)
          //     list.splice(index,1)
          //     if(list.length > 0){
          //       list = this.getTreeData(list)
          //     }
          //   }
          // }
          for(let i = 0; i < list.length; i++){
            let ele = list[i]
            if(ele.REGIONTYPE < 2){
              ele.Child = this.getTreeData(ele.Child)
            }else{
              delete ele.Child
            }
          }
          return list
        },
        getPostList() {
            this.showLoading("请稍后")
            var params = {
                PAGEINDEX: this.PageIndex,
                PAGESIZE: this.PageSize,
                NAME: this.name,
                REGIONSNOWID:this.regId,
            }
            this.$http.post("Project/GetDataList", params)
                .then((response) => {
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.postList = response.data.DATA
                        this.Total = response.data.TOTALCOUNT
                    } else {
                        this.postList = []
                        this.Total = 0
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                    this.errorFlag(JSON.stringify(error))
                    this.hideLoading()
                    this.postList = []
                    this.Total = 0
                });
        },
        searchPost() {
            this.PageIndex = 1
            this.getPostList()
        },
        addPost() {
            this.postInfo = {
                name: "",
                regionsnowid: "",
                taskarea: "",
                jobarea: "",
                subsidymoney: "",
                time: "",
                signtime: "",
            }
            this.doTypeStr = "添加项目"
            this.doType = "1"
            this.postModal = true
        },
        sendAdd() {
            var st= this.comjs.getDateStrByDate(this.postInfo.time[0])
            var et= this.comjs.getDateStrByDate(this.postInfo.time[1])
            var params = {
                name: this.postInfo.name,
                regionsnowid: this.postInfo.regionsnowid[this.postInfo.regionsnowid.length - 1],
                taskarea: parseFloat(this.postInfo.taskarea),
                jobarea: parseFloat(this.postInfo.jobarea),
                subsidymoney: parseFloat(this.postInfo.subsidymoney),
                starttime: st,
                endtime: et,
                signtime: this.comjs.getDateStrByDate(this.postInfo.signtime),
            }
            this.canDo = false
            this.showLoading("请稍后")
            this.$http.post("Project/InsertData", params)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已添加")
                        this.postModal = false
                        this.getPostList()
                    } else {
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },
        editPost(item) {
            var ts = [new Date(item.STARTTIME), new Date(item.ENDTIME)]
            this.postInfo = {
                snowid: item.SNOWID,
                name: item.NAME,
                regionsnowid: item.REGIONSNOWID,
                taskarea: item.TASKAREA,
                jobarea: item.JOBAREA,
                subsidymoney: item.SUBSIDYMONEY,
                time: ts,
                signtime: new Date(item.SIGNTIME),
            }
            this.doTypeStr = "修改项目"
            this.doType = "2"
            this.postModal = true
        },
        sendEdit() {
            var st= this.comjs.getDateStrByDate(this.postInfo.time[0])
            var et= this.comjs.getDateStrByDate(this.postInfo.time[1])
            var params = {
                snowid:this.postInfo.snowid,
                name: this.postInfo.name,
                regionsnowid: this.postInfo.regionsnowid,
                taskarea: parseFloat(this.postInfo.taskarea),
                jobarea: parseFloat(this.postInfo.jobarea),
                subsidymoney: parseFloat(this.postInfo.subsidymoney),
                starttime: st,
                endtime: et,
                signtime: this.comjs.getDateStrByDate(this.postInfo.signtime),
            }
            this.canDo = false
            this.showLoading("请稍后")
            this.$http.post("Project/UpdateData", params)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已修改")
                        this.postModal = false
                        this.getPostList();
                    } else {
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },
        delPost(item) {
            if (this.canDo) {
                this.$confirm('确定删除：' + item.NAME + '？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.sendDel(item.SNOWID)
                }).catch(() => {
                    this.$message.info("已取消")
                });
            }

        },
        sendDel(id) {
            this.canDo = false
            this.showLoading("请稍后")
            this.$http.post("Project/DeleteData", { SnowID: id })
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已删除")
                        this.postModal = false
                        this.getPostList()
                    } else {
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },
        PGChange(e) {
            this.PageIndex = e
            this.getPostList()
        },
        submitPostForm() {
            if (this.canDo) {
                this.$refs.uForm.validate((valid) => {
                    if (valid) {
                        if (this.doType == "1") {
                            this.sendAdd()
                        } else if (this.doType == "2") {
                            this.sendEdit()
                        }
                    } else {
                        return false;
                    }
                });
            }
        },
        handleChange(e){
          if(e.length === 1){
            this.postInfo.regionsnowid = ''
            this.$nextTick(() => {
              // 清空级联选择器选中状态
              this.$refs.cascader.$refs.panel.clearCheckedNodes()
              // 清除高亮
              this.$refs.cascader.$refs.panel.activePath = []
            })
            return this.$message.error('选中区域错误,只能选中最后一级')
          }
        },
    },
    computed: {
    },
    watch: {
    },
};
</script>
<style ></style>