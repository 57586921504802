import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'mint-ui/lib/style.css'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import qs from 'qs';
import VueCookies from 'vue-cookies'
import md5 from 'js-md5';
import comjs from '../public/statics/js/common.js'

import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
Vue.use(mavonEditor)
Vue.config.productionTip = false


//上传 ajax
const upload = axios.create({
  baseURL: comjs.baseUrl,
  timeout: 1000 * 6 * 20,
  headers: {
    'Content-Type': 'multipart/form-data' ,
  },
})
upload.interceptors.request.use(
  function (config) {
    var a = window.localStorage.getItem("ny_token")
    if (a) {
      config.headers['Authorization'] = "Bearer "+a
    }
    // if (xa) {
    //   config.headers['X-Authorization'] = "Bearer "+xa
    // }
    return config
  },
  function (error) {
    return Promise.reject(error)
  },
)
Vue.prototype.$upload = upload;

//下载
const download = axios.create({
  baseURL: comjs.baseUrl,
  timeout: 50000000,
  responseType: 'blob',
})
download.interceptors.request.use(
  function (config) {
    var a = window.localStorage.getItem("ny_token")
    if (a) {
      config.headers['Authorization'] = "Bearer "+a
    }
    // if (xa) {
    //   config.headers['X-Authorization'] = "Bearer "+xa
    // }
    return config
  },
  function (error) {
    return Promise.reject(error)
  },
)
Vue.prototype.$download = download;

//普通ajax
const $axios = axios.create({
  baseURL: comjs.baseUrl,
  timeout: 5000000,
});
$axios.interceptors.request.use(
  function (config) {
    var a = window.localStorage.getItem("ny_token")
    if (a) {
      config.headers['Authorization'] = "Bearer "+a
    }
    // if (xa) {
    //   config.headers['X-Authorization'] = "Bearer "+xa
    // }
    return config
  },
  function (error) {
    return Promise.reject(error)
  },
)
const $newAxios = axios.create({
    baseURL: comjs.baseUrl,
    timeout: 120000,
});
$newAxios.interceptors.request.use(
    function (config) {
        if(store.state.token && store.state.token!=""){
            config.headers['Token'] = store.state.token
        }
        return config
    },
    function (error) {
        return Promise.reject(error)
    },
)

Vue.prototype.$http = $axios;
Vue.prototype.$newHttp = $newAxios;


//组件
Vue.prototype.$qs = qs;
Vue.prototype.$md5 = md5;
Vue.prototype.comjs = comjs;
Vue.use(ElementUI);
Vue.use(VueCookies)
VueCookies.config('30d')

//注销
var pcLogout= ()=>{
  store.commit('setToken', "");  
  VueCookies.remove("ny_setToken")

  localStorage.removeItem("ny_type");
  localStorage.removeItem("ny_token");
  localStorage.removeItem("ny_snowid");
  localStorage.removeItem("ny_turename");
  localStorage.removeItem("ny_regid");
  localStorage.removeItem("ny_reguserid");
  localStorage.removeItem("ny_hzsid");

  if(router.currentRoute.fullPath!="/" && router.currentRoute.fullPath!="/AdminLogin"){
    router.replace("/")
  }
}
Vue.prototype.logout = pcLogout


var errorFlag=(str)=>{
  console.log(str)
  if(str.indexOf('code 401') != -1){
    pcLogout()
  }
}
Vue.prototype.errorFlag = errorFlag

//等待框
var loadItem=null
var showLoading=function(txt){
  loadItem = this.$loading({
    lock: true,
    text: txt,
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)',
  });
}
var hideLoading=function(){
  loadItem.close()
}

Vue.prototype.loadItem = loadItem
Vue.prototype.showLoading = showLoading
Vue.prototype.hideLoading = hideLoading


var queryData={}
var getQueryData=function(routername){
  return queryData[routername]
}
var setQueryData=function(routername,params){
  queryData[routername]=params
}
Vue.prototype.getQueryData = getQueryData
Vue.prototype.setQueryData = setQueryData




new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
