<template>
    <div class="views-mainA" :style="{ height: tableHeight + 'px' }">
        <div class="tableBox" style="top:66px;">
            <div class="views-main-search" style="padding:0 16px;">
                <el-date-picker v-model="dates" type="datetimerange" range-separator="至" start-placeholder="作业开始时间"
                    end-placeholder="作业结束时间" style="margin-right:20px;" :clearable="false">
                </el-date-picker>
                <el-button type="primary" @click="searchPost" style="margin-right:20px;">查询</el-button>
                <el-button type="warning" @click="exportPost">导出作业报告</el-button>
            </div>
            <div class="views-main-body" style="margin-top:0;">
                <el-table :data="postList" stripe style="width: 100%">
                    <el-table-column type="expand">
                        <template slot-scope="props">
                            <div class="farmerList">
                                <p class="farmerItem" v-for="item in props.row.FarMerList" :key="item.SNOWID">
                                    {{item.NAME}}({{item.IDCARD}})
                                </p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="STARTGPSTIME" label="作业开始时间" width="160"></el-table-column>
                    <el-table-column prop="ENDGPSTIME" label="作业结束时间" width="160"></el-table-column>
                    <el-table-column prop="AREA" label="作业面积"></el-table-column>
                    <el-table-column prop="CROPSSNAME" label="农作物"></el-table-column>
                    <el-table-column prop="MECHINERYNAME" label="农机"></el-table-column>
                    <el-table-column prop="TOOLNNAME" label="农具"></el-table-column>
                    <el-table-column prop="BREADTH" label="幅宽"></el-table-column>
                    <el-table-column prop="REGIONNAME" label="区域"></el-table-column>
                    <el-table-column prop="SYSUSERNAME" label="合作社" width="150"></el-table-column>
                    <el-table-column prop="SERVICELINK" label="服务环节">
                        <template slot-scope="scope">
                            <span v-if="scope.row.SERVICELINK == 0">耕（旋耕）</span>
                            <span v-else-if="scope.row.SERVICELINK == 1">种（机播）</span>
                            <span v-else-if="scope.row.SERVICELINK == 2">防（防治）</span>
                            <span v-else-if="scope.row.SERVICELINK == 3">收（收割）</span>
                            <span v-else-if="scope.row.SERVICELINK == 4">耕（深翻）</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="CREATETIME" label="创建时间" width="160"></el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next" :current-page="PageIndex" :page-size="PageSize"
                    :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Ruser",
    props: {
        mechineid: {
            type: String,
            default: "",
        },
        sysuserid: {
            type: String,
            default: "",
        },
        tableHeight: {
            type: Number,
            default: 600,
        },
    },
    data() {
        return {
            canDo: true,
            postList: [],
            PageIndex: 1,
            PageSize: 6,
            Total: 0,
            dates: null,
        };
    },
    mounted() {
        this.loadItem = null
    },
    methods: {
        resetDates() {
            var now = new Date();
            var monthStart = new Date(now.getFullYear(), now.getMonth(), 1); 
            var monthEnd = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59); 
            this.dates = [monthStart, monthEnd]
            this.getPostList()
        },
        getPostList() {
            this.showLoading("请稍后")
            var st = this.comjs.getDateStrByDate(this.dates[0])
            var et = this.comjs.getDateStrByDate(this.dates[1])
            var params = {
                pageIndex: this.PageIndex,
                pageSize: this.PageSize,
                StartTime: st,
                EndTime: et,
                MechinerySnowID: this.mechineid,
                SysUserSnowID: this.sysuserid,
            }
            this.$http.post("WorkReport/GetDataList", params)
                .then((response) => {
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.postList = response.data.DATA
                        this.Total = response.data.TOTALCOUNT
                    } else {
                        this.postList = []
                        this.Total = 0
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                    this.hideLoading()
                    this.postList = []
                    this.Total = 0
                });
        },
        searchPost() {
            this.PageIndex = 1
            this.getPostList()
        },
        PGChange(e) {
            this.PageIndex = e
            this.getPostList()
        },
        exportPost() {
            if (this.canDo) {
                if (this.Total > 0) {
                    this.showLoading("请稍后")
                    var st = this.comjs.getDateStrByDate(this.dates[0])
                    var et = this.comjs.getDateStrByDate(this.dates[1])
                    var params = {
                        MechinerySnowID: this.mechineid,
                        SysUserSnowID: this.sysuserid,
                        StartTime: st,
                        EndTime: et,
                    }
                    this.canDo = false
                    this.$download.post("WorkReport/ExportReportList", params,{ responseType: 'blob',timeout:300000})
                        .then((resJson) => {
                            this.hideLoading()
                            this.canDo = true
                            if (resJson) {
                                var name = st+"-"+et+"作业报告"
                                let blob = new Blob([resJson.data], { type: 'application/octet-stream' })
                                if (window.navigator.msSaveOrOpenBlob) {
                                    window.navigator.msSaveBlob(blob, name + ".xls")
                                } else {
                                    let downloadElement = document.createElement('a')
                                    let href = window.URL.createObjectURL(blob) //创建下载的链接
                                    downloadElement.href = href
                                    downloadElement.download = name + ".xls"
                                    document.body.appendChild(downloadElement)
                                    //此写法兼容火狐
                                    let evt = document.createEvent('MouseEvents')
                                    evt.initEvent('click', false, false)
                                    downloadElement.dispatchEvent(evt)
                                    document.body.removeChild(downloadElement)
                                }
                                this.$message.success('操作成功!')
                            } else {
                                this.$message.error("数据导出失败")
                            }
                        })
                        .catch((error) => {
                            this.errorFlag(JSON.stringify(error))
                            this.hideLoading()
                            this.canDo = true
                        });
                } else {
                    this.$message.error("当前时间段内暂无作业报告")
                }
            }
        },
    },
    computed: {
    },
    watch: {
    },
};
</script>
<style scoped>
    .farmerList{overflow:hidden;padding:0px 12px;}
    .farmerList .farmerItem{background-color:#F1F1F1;border-radius:3px;padding:4px 10px 4px 10px;font-size:12px;float:left;margin-right:12px;}
</style>