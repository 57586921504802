import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    xzqSnowid:"",
    xzqUserSnowid:"",
    sysType:"",
    initRouter:"",
    openPage:"",
    token:"",
    admin:"",
    endTime:"",
    snowid:"",
    name:"",
    loginName:"",
    password:"",
    roleList:[],
    
  },
  getters: {
    getXzqSnowid : state => state.xzqSnowid,
    getXzqUserSnowid : state => state.xzqUserSnowid,
    getSysType : state => state.sysType,
    getInitRouter: state => state.initRouter,
    getOpenPage: state => state.openPage,

    getToken: state => state.token,
    getAdmin: state => state.admin,
    getEndTime: state => state.endTime,
    getGuid: state => state.guid,
    getName: state => state.name,
    getLoginName: state => state.loginName,
    getPassword: state => state.password,
    getRoleList: state => state.roleList,
  },
  mutations: {
    setXzqSnowid(state, data) {
      state.xzqSnowid = data
    },
    setXzqUserSnowid(state, data) {
      state.xzqUserSnowid = data
    },
    setSysType(state, data) {
      state.sysType = data
    },

    setInitRouter(state, data) {
      state.initRouter = data
    },
    setOpenPage(state, data) {
      state.openPage = data
    },


    setToken(state, data) {
        state.token = data
    },
    setAdmin(state, data) {
      state.admin = data
    },
    setEndTime(state, data) {
      state.endTime = data
    },
    setSnowid(state, data) {
      state.snowid = data
    },
    setName(state, data) {
      state.name = data
    },
    setLoginName(state, data) {
      state.loginName = data
    },
    setPassword(state, data) {
      state.password = data
    },
    setRoleList(state, data) {
      state.roleList = data
    },

  },
  actions: {
    setXzqSnowid({commit, state}, data) {
      commit('setXzqSnowid', data)
    },
    setXzqUserSnowid({commit, state}, data) {
      commit('setXzqUserSnowid', data)
    },
    setSysType({commit, state}, data) {
      commit('setSysType', data)
    },
    setInitRouter({commit, state}, data) {
      commit('setInitRouter', data)
    },
    setOpenPage({commit, state}, data) {
      commit('setOpenPage', data)
    },

    setToken({commit, state}, data) {
      commit('setToken', data)
    },
    setAdmin({commit, state}, data) {
      commit('setAdmin', data)
    },
    setEndTime({commit, state}, data) {
      commit('setEndTime', data)
    },
    setSnowid({commit, state}, data) {
      commit('setSnowid', data)
    },
    setName({commit, state}, data) {
      commit('setName', data)
    },
    setLoginName({commit, state}, data) {
      commit('setLoginName', data)
    },
    setPassword({commit, state}, data) {
      commit('setPassword', data)
    },
    setRoleList({commit, state}, data) {
      commit('setRoleList', data)
    },
  },
  modules: {
  }
})
