<template>
  <div class="views-main">
    <div class="views-main-title">
      <p class="txt">暂无数据!</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "QualityControl"
}
</script>

<style scoped>

</style>