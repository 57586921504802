<template>
  <div id="container">
    <div class="partBox">
      <div class="login_box">
        <div class="login_nav">
          <p v-if="currentPath === '/AdminLogin'" class="login_title" :class="navType == '1' ? 'login_navOn' : ''" @click="changeNavType(1)">管理员登录</p>
          <p v-if="currentPath === '/'" class="login_title" :class="navType == '2' ? 'login_navOn' : ''" @click="changeNavType(2)">区域用户登录</p>
        </div>
        <div style="overflow:hidden;">
          <div class="login_row">
            <i class="fa fa-user"></i>
            <input type="text" id="LOGINNAME" class="login_input" placeholder="请输入用户名" autocomplete="off"
              v-model="name" />
          </div>
          <div class="login_row">
            <i class="fa fa-lock"></i>
            <input type="password" id="PASSWORD" class="login_input" placeholder="请输入密码" autocomplete="off"
              v-model="pwd" />
          </div>
          <p class="login_error">{{ errorMsg }}</p>
          <button id="loginBtn" class="login_btn" :class="canLogin ? '' : 'loginBtn_doing'" v-on:click="doLogin"
            v-loading.fullscreen.lock="fullscreenLoading">
            登　　录
          </button>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: "Login",
  data() {
    return {
      navType: "1",
      registerModal: false,
      fullscreenLoading: false,
      errorMsg: "",
      canLogin: true,
      name: "",
      pwd: "",
      currentPath: '/'
    };
  },
  created() {
    this.currentPath = this.$route.path
    // console.log(this.currentPath)
    if(this.currentPath === '/'){
      this.navType = 2
    }else if(this.currentPath === '/AdminLogin'){
      this.navType = 1
    }
  },
  mounted() {
    this.loadItem = null
    this.initUserInfo()
  },
  methods: {
    changeNavType(type) {
      this.navType = type
    },
    initUserInfo() {
      var loginValid = false
      if (this.$cookies.isKey("ny_setSysType") && this.$cookies.isKey("ny_setToken")) {
        loginValid = true
        this.$store.commit('setSysType', this.$cookies.get("ny_setSysType"));
        this.$store.commit('setToken', this.$cookies.get("ny_setToken"));
        this.$store.commit('setName', this.$cookies.get("ny_setName"));
        this.$store.commit('setLoginName', this.$cookies.get("ny_setLoginName"));
        this.$store.commit('setName', this.$cookies.get("ny_setName"));
        this.$store.commit('setPassword', this.$cookies.get("ny_setPassword"));
        this.$store.commit('setSnowid', this.$cookies.get("ny_snowid"));
      }
      if (loginValid) {
        var sysType = this.$cookies.get("ny_setSysType")
        if (this.$store.state.openPage != "" && this.$route.path !== '/AdminLogin') {
          this.$router.replace("/" + this.$store.state.openPage);
          if (sysType == "2") {
            this.$store.commit('setXzqSnowid', this.$cookies.get("ny_setXzqSnowid"));
            this.$store.commit('setXzqUserSnowid', this.$cookies.get("ny_setXzqUserSnowid"));
            this.$router.replace("/AdminRegionManage");
          }
        } else {
          if (sysType == "1") {
            this.$router.replace("/AdminUserManage");
          } else if (sysType == "2") {
            this.$store.commit('setXzqSnowid', this.$cookies.get("ny_setXzqSnowid"));
            this.$store.commit('setXzqUserSnowid', this.$cookies.get("ny_setXzqUserSnowid"));
            this.$router.replace("/AdminRegionManage");
          }

        }
      } else {
        this.logout(false)
      }
    },
    doLogin() {
      this.doLoginAdmin()
    },
    doLoginAdmin() {
      if (this.canLogin) {
        if (this.name == "" || this.pwd == "") {
          this.errorMsg = "用户名或密码不能为空";
        } else {
          this.fullscreenLoading = true;
          this.errorMsg = "";
          this.canLogin = false;
          var params = {
            LoginName: this.name,
            PassWord: this.pwd,
          };
          this.$http
            .post("SysUser/SysUserLoginIn", params)
            .then((response) => {
              this.fullscreenLoading = false;
              if (response.data.SUCCESS) {
                var d = response.data.DATA
                var lf=false
                if(this.navType=='1'){
                  if(d.SysUser.TYPE ==1){
                    lf=true
                  }else{
                    this.canLogin = true;
                    this.$message.error('管理员账号或密码错误');
                  }
                }else{
                  if(d.SysUser.TYPE ==2){
                    lf=true
                  }else{
                    this.canLogin = true;
                    this.$message.error('区域用户账号或密码错误');
                  }
                }
                if(lf){
                    this.$store.commit('setSysType', d.SysUser.TYPE); 
                    this.$store.commit('setToken', d.Token);  
                    this.$store.commit('setName', d.SysUser.DISPLAYNAME);
                    this.$store.commit('setLoginName', d.SysUser.LOGINNAME);
                    this.$store.commit('setPassword', this.pwd);
                    this.$store.commit('setSnowid', d.SysUser.SNOWID);

                    this.$cookies.set("ny_setSysType",d.SysUser.TYPE)
                    this.$cookies.set("ny_setToken",d.Token)
                    this.$cookies.set("ny_setName",d.SysUser.DISPLAYNAME)
                    this.$cookies.set("ny_setLoginName",d.SysUser.LOGINNAME)
                    this.$cookies.set("ny_snowid",d.SysUser.SNOWID)
                    this.$cookies.set("ny_setPassword",this.pwd)

                    localStorage.setItem("ny_type", d.SysUser.TYPE);
                    localStorage.setItem("ny_token", d.Token);
                    localStorage.setItem("ny_snowid", d.SysUser.SNOWID);
                    localStorage.setItem("ny_turename", d.SysUser.DISPLAYNAME);
                    localStorage.setItem("ny_regid", d.RegionSnowID);
                    localStorage.setItem("ny_reguserid", d.RegionUserSnowID);
                    localStorage.setItem("ny_hzsid", d.CooperativeSnowID);

                    if(d.SysUser.TYPE==1){
                      this.$router.replace("/AdminUserManage");
                      this.$router.replace("/AdminUserManage");
                    }else if(d.SysUser.TYPE==2){
                      this.$store.commit('setXzqSnowid', d.RegionSnowID); 
                      this.$cookies.set("ny_setXzqSnowid",d.RegionSnowID)
                      this.$store.commit('setXzqUserSnowid', d.RegionUserSnowID); 
                      this.$cookies.set("ny_setXzqUserSnowid",d.RegionUserSnowID)
                      this.$router.replace("/AdminRegionManage");
                    }
                }
                
              } else {
                this.canLogin = true;
                var msg = ''
                if (response.data.MSG) {
                  msg = response.data.MSG
                } else {
                  msg = "账号或密码不正确"
                }
                this.$message.error(msg);
              }
            })
            .catch((error) => {
              this.canLogin = true;
              this.fullscreenLoading = false;
              this.$message.error("登录失败，请稍后再试");
            });
        }
      }
    },
  },
  computed: {},
  watch: {},
};
</script>
<style scoped>
input::-webkit-input-placeholder {
  color: #fff;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-width: 1200px;
  min-height: 600px;
  position: relative;
}

#container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: url("../../public/statics/img/ubg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 666;
}

.partBox {
  width: 330px;
  overflow: hidden;
  border: 0;
  margin: 18% auto;
}

.codeBox {
  width: 260px;
  margin: 0px auto;
}

.codeBox img {
  margin-top: 20px;
  width: 100%;
  overflow: hidden;
}

.codeBox p {
  font-size: 16px;
  color: #FFFFFF;
  margin-top: 10px;
  text-align: center;
}

.options-login,
.options-register {
  width: 320px;
  padding: 0 5px;
  font-size: 14px;
  position: absolute;
  display: none;
  z-index: 999;
}

.options-login .register,
.options-login .forget {
  float: right;
  margin-left: 20px;
  cursor: pointer;
  color: #2dd1b1;
}

.options-register .login {
  color: #fff;
  float: right;
  margin-left: 20px;
}

.options-register .login a {
  cursor: pointer;
  color: #2dd1b1;
}

.login_box {
  width: 320px;
  overflow: hidden;
  padding: 0 5px;
}

.login_row {
  width: 100%;
  height: 46px;
  border-radius: 23px;
  background-color: rgba(255, 255, 255, 0.2);
  overflow: hidden;
  margin-bottom: 20px;
}

.login_row i {
  font-size: 16px;
  color: #fff;
  float: left;
  margin-top: 16px;
  margin-left: 16px;
}

.login_row input {
  width: 256px;
  height: 30px;
  line-height: 31px;
  font-size: 15px;
  float: left;
  margin-top: 8px;
  margin-left: 16px;
  background-color: transparent;
  border: 0;
  color: #fff;
  outline: none;
}

.login_row .sel {
  width: 250px;
  height: 30px;
  line-height: 31px;
  font-size: 15px;
  float: left;
  margin-top: 8px;
  margin-left: 16px;
  background-color: transparent;
  border: 0;
  color: #fff;
  outline: none;
}

.login_row .sel option {
  background-color: #ADADAD;
}

.sel .el-input__inner {
  background-color: transparent !important;
  ;
}

.active {
  background-color: rgba(255, 255, 255, 0.4) !important;
}

.login_row_last {
  margin-bottom: 0 !important;
}

.login_error {
  height: 32px;
  font-size: 14px;
  color: #ff4e44;
  line-height: 35px;
}

.login_btn {
  background-color: rgba(44, 209, 177, 0.8);
  color: #fff;
  font-size: 16px;
  width: 310px;
  height: 46px;
  border-radius: 20px;
  cursor: pointer;
  line-height: 47px;
  border: 0;
}

.login_nav {
  width: 100%;
  margin-bottom: 20px;
  overflow: hidden;
}

.login_navOn {
  color: rgb(44, 209, 177) !important;
  border-bottom: 2px solid rgb(44, 209, 177) !important;
  font-weight: bold;
}

.login_title {
  font-size: 18px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  width: 50%;
  float: left;
  padding-bottom: 5px;
  border-bottom: 2px solid transparent;
}

.login_row select {
  width: 320px;
  display: inline-block;
  margin-right: 20px;
  height: 45px;
  border-color: #aaa;
  border-radius: 4px;
  outline: none;
}

.login_doing {
  color: #33cc99 !important;
}

.loginBtn_doing {
  cursor: wait, auto !important;
  background-color: rgba(187, 190, 196, 0.8);
}</style>
