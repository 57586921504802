<template>
  <div class="views-main">
    <div class="views-main-title">
      <p class="txt">作业统计</p>
    </div>
    <div class="views-main-search">
      <el-cascader
          v-model="currentCascade"
          :options="cascadeTreeData"
          :props="cascadeProps"
          style="width: 300px"></el-cascader>
      <el-date-picker
          v-model="date"
          style="margin-right: 20px;margin-left: 20px;"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
      </el-date-picker>
      <el-button @click="search" type="primary">查询</el-button>
    </div>
    <div class="tableChartsArea">
      <div style="width: 50%;height: 100%;overflow: auto">
        <el-table :data="postList" stripe style="width: 100%">
          <el-table-column prop="Name" label="名称" width="120"></el-table-column>
          <el-table-column prop="RegionName" label="区域"></el-table-column>
          <el-table-column prop="AreaSum" label="任务面积"></el-table-column>
          <el-table-column prop="VilidSum" label="作业面积"></el-table-column>
          <el-table-column prop="f0" label="旋耕面积"></el-table-column>
          <el-table-column prop="f1" label="机播面积"></el-table-column>
          <el-table-column prop="f2" label="防治面积"></el-table-column>
          <el-table-column prop="f3" label="收割面积"></el-table-column>
          <el-table-column prop="f4" label="深翻面积"></el-table-column>
        </el-table>
      </div>
      <div id="myMap" style="width: 50%;height: 100%"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: "OperationStatistics",
  data() {
    return {
      postList: [],
      PageIndex:1,
      PageSize:10,
      Total:0,
      charts: null,
      currentCascade: '',
      cascadeTreeData: [],
      date: '',
      cascadeProps: {
        value: 'SNOWID',
        label: 'NAME',
        children: 'Child',
        checkStrictly: true,
      },
      sysType: '',
    }
  },
  async created() {
    await this.getRegionData()
    this.getPostList()
  },
  mounted() {
    this.sysType = this.$store.state.sysType
    if(this.sysType =="2"){
      this.regId= this.$store.state.xzqSnowid
    }
    this.loadItem = null
  },
  methods: {
    search(){
      this.getPostList()
    },
    // 初始化图表
    initCharts() {
      if (!this.charts) {
        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementById('myMap'));
        let f0Data = [],
            f1Data = [],
            f2Data = [],
            f3Data = [],
            f4Data = []
        let xData = []
        this.postList.forEach((item)=>{
          f0Data.push(item.f0)
          f1Data.push(item.f1)
          f2Data.push(item.f2)
          f3Data.push(item.f3)
          f4Data.push(item.f4)
          xData.push(item.Name)
        })
        // 绘制图表
        myChart.setOption({
          grid: {
            top: '33px',
            right: '15px',
            left: '50px',
            bottom: '100px'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'none'
            },
            formatter: '{b0}</br>{a0}：{c0}</br>{a1}：{c1}</br>{a2}：{c2}</br>{a3}：{c3}</br>{a4}：{c4}'
          },
          dataZoom: [{
            type: 'slider',//图表下方的伸缩条
            show: true, //是否显示
            height: '15px',
            realtime: true, //拖动时，是否实时更新系列的视图
            start: 0, //伸缩条开始位置（1-100），可以随时更改
            end: 100, //伸缩条结束位置（1-100），可以随时更改
            handleStyle: {
              color: '#0B3C6F',
              shadowBlur: 3,
              shadowColor: 'rgba(0, 0, 0, 0.6)',
              shadowOffsetX: 2,
              shadowOffsetY: 2
            },
            bottom: '30px'
          }],
          legend: {
            type: "scroll",
            right: '10',
            top: '0',
            data: ['旋耕面积', '机播面积', '防治面积', '收割面积', '深翻面积'],
            itemGap: 25,
            itemWidth: 16,
            itemHeight: 16,
            textStyle: {
              fontSize: '13',
              color: '#666666',
            },
          },
          xAxis: {
            data: xData,
          },
          yAxis: {},
          series: [
            {
              name: '旋耕面积',
              type: 'bar',
              data: f0Data,
            },
            {
              name: '机播面积',
              type: 'bar',
              data: f1Data,
            },
            {
              name: '防治面积',
              type: 'bar',
              data: f2Data,
            },
            {
              name: '收割面积',
              type: 'bar',
              data: f3Data,
            },
            {
              name: '深翻面积',
              type: 'bar',
              data: f4Data,
            },
          ]
        });
      }
    },
    // 获取所有的区域数据
    getRegionData(){
      let params={
        PARENTSNOWID: '',
      }
      if(this.$store.state.sysType =="2"){
        params.PARENTSNOWID= this.$store.state.xzqSnowid
      }
      let that = this
      return new Promise(function (resolve) {
        that.$http.post("Region/GetDataTree", params)
            .then((response) => {
              if (response.data.SUCCESS) {
                let list = response.data.DATA
                if(that.$store.state.sysType =="2"){
                  that.cascadeTreeData = list
                  that.currentCascade = list[0].SNOWID
                }else{
                  list.forEach((item)=>{
                    if(item.Child && item.Child.length > 0){
                      item.Child.forEach((ele,index)=>{
                        that.cascadeTreeData.push(ele)
                        if(index === 0 && !that.currentCascade){
                          that.currentCascade = ele.SNOWID
                        }
                      })
                    }
                  })
                }
                resolve(that.currentCascade)
              } else {
                that.$message.error(response.data.MSG)
              }
            })
            .catch((error) => {
              that.errorFlag(JSON.stringify(error))
            });
      })
    },
    // 获取表格和图表数据
    getPostList(){
      this.showLoading("请稍后")
      var params={
        StartTime: '',
        EndTime: '',
        RegionSnowID: ''
      }
      if(Array.isArray(this.currentCascade) && this.currentCascade.length > 0){
        params.RegionSnowID = this.currentCascade[this.currentCascade.length - 1]
      }else{
        params.RegionSnowID = this.currentCascade
      }
      if(this.date.length > 0){
        params.StartTime = this.date[0]
        params.EndTime = this.date[1]
      }
      this.$http.post("Statistics/GetWorkStatisics", params)
          .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
              response.data.DATA.userWorkStatisics.forEach((item)=>{
                if(item.serviceLinks.length > 0){
                  item.serviceLinks.forEach((ele)=>{
                    if(ele.ServiceLink == 0){
                      item.f0 = ele.Area.toFixed(4)
                    }else if(ele.ServiceLink == 1){
                      item.f1 = ele.Area.toFixed(4)
                    }else if(ele.ServiceLink == 2){
                      item.f2 = ele.Area.toFixed(4)
                    }else if(ele.ServiceLink == 3){
                      item.f3 = ele.Area.toFixed(4)
                    }else if(ele.ServiceLink == 4){
                      item.f4 = ele.Area.toFixed(4)
                    }
                  })
                }
                item.RegionName = response.data.DATA.RegionName
              })
              this.postList=response.data.DATA.userWorkStatisics
              this.Total=response.data.DATA.userWorkStatisics.length
              setTimeout(()=>{
                this.initCharts()
              })
            } else {
              this.postList=[]
              this.Total=0
              this.$message.error(response.data.MSG)
            }
          })
          .catch((error) => {
            this.errorFlag(JSON.stringify(error))
            this.hideLoading()
            this.postList=[]
            this.Total=0
          });
    }
  },
}
</script>

<style scoped>
.tableChartsArea {
  width: 86vw;
  height: 82vh;
  margin-top: 20px;
  display: flex;
}
</style>