<template>
  <div class="views-main">
    <div class="views-main-title">
      <p class="txt">作业地图</p>
    </div>
    <div class="mainArea" style="width: 86vw;height: 86vh;position: relative">
      <div id="myMap" style="width: 100%;height: 100%"></div>
      <div class="searchRegion">
        <div style="display: flex;align-items: center">
          <el-cascader
              v-model="currentCascade"
              :options="cascadeTreeData"
              :props="cascadeProps"
              @change="handleChange"
              style="width: 350px"></el-cascader>
          <!--          <el-select v-model="currentLink" placeholder="请选择" filterable clearable style="margin-left: 10px;margin-right: 10px;">-->
          <!--            <el-option v-for="item in links" :key="item.value" :label="item.name" :value="item.value"></el-option>-->
          <!--          </el-select>-->
          <!--          <el-date-picker-->
          <!--              style="margin-right: 20px"-->
          <!--              type="daterange"-->
          <!--              value-format="yyyy-MM-dd"-->
          <!--              range-separator="至"-->
          <!--              start-placeholder="开始日期"-->
          <!--              end-placeholder="结束日期">-->
          <!--          </el-date-picker>-->
        </div>
      </div>
      <el-card class="box-card treeRegion" style="position: absolute;top: 75px;left: 20px;width: 350px;z-index: 11">
        <el-input
            placeholder="输入关键字进行过滤"
            v-model="filterText">
        </el-input>
        <el-tree
            highlight-current
            style="height: 250px;overflow: auto"
            :props="defaultProps"
            :filter-node-method="filterNode"
            lazy
            :data="reginList"
            :load="loadNode"
            @node-click="nodeClick"
            ref="tree">
        </el-tree>
      </el-card>
      <!--      <el-card class="box-card" style="position: absolute;top: 420px;left: 20px;width: 350px;height: 350px;">-->
      <!--        -->
      <!--      </el-card>-->
      <el-button v-if="sysType == '1'" @click="screenshotDialogVisible = true" type="danger" size="small"
                 class="screenshotBtn">截图
      </el-button>
    </div>
    <el-dialog
        title="截图条件"
        :visible.sync="screenshotDialogVisible"
        width="40%"
        :before-close="screenshotHandleClose">
      <el-form>
        <!--        <el-form-item label="活动性质">-->
        <!--          <el-checkbox-group v-model="checkList">-->
        <!--            <el-checkbox label="美食/餐厅线上活动" name="type"></el-checkbox>-->
        <!--            <el-checkbox label="地推活动" name="type"></el-checkbox>-->
        <!--            <el-checkbox label="线下主题活动" name="type"></el-checkbox>-->
        <!--            <el-checkbox label="单纯品牌曝光" name="type"></el-checkbox>-->
        <!--          </el-checkbox-group>-->
        <!--        </el-form-item>-->
        <el-form-item label="截图日期">
          <el-date-picker
              v-model="screenshotDate"
              style="margin-right: 20px"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="screenshotDialogVisible = false">取 消</el-button>
        <el-button @click="takeScreenshot" type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {saveAs} from 'file-saver';
import domtoimage from 'dom-to-image';
import * as JSZip from 'jszip';

export default {
  name: "OperationMap",
  data() {
    return {
      map: null,
      screenshotDialogVisible: false,
      cascadeTreeData: [], // 级联树形数据
      checkList: [],
      filterText: '',
      currentLink: '', // 环节选中
      links: [
        {
          name: '旋耕',
          value: 0
        },
        {
          name: '机播',
          value: 1
        },
        {
          name: '防治',
          value: 2
        },
        {
          name: '收割',
          value: 3
        },
        {
          name: '深翻',
          value: 4
        },
      ], // 环节数组
      currentCascade: '', // 当前级联选中
      reginList: [],
      trackLineList: [], // 轨迹对象数组
      cascadeProps: {
        value: 'SNOWID',
        label: 'NAME',
        // children: 'Child',
        checkStrictly: true,
      },
      defaultProps: {
        children: 'children',
        label: 'DISPLAYNAME',
        isLeaf: 'leaf'
      },
      sysType: '',
      screenshotDate: '', // 截图日期
      screenshotImgList: [], // 截屏图片数组
      zip: null,
      img: null
    }
  },
  created() {
  },
  mounted() {
    this.sysType = this.$store.state.sysType
    if (this.sysType == "2") {
      this.regId = this.$store.state.xzqSnowid
    }
    this.loadItem = null
    this.initMap()
    this.zip = new JSZip(); // 实例化zip
    this.img = this.zip.folder(""); // zip包内的文件夹名字
  },
  methods: {
    // 初始化地图
    initMap() {
      if (!this.map) {
        var center = new window.qq.maps.LatLng(32.151818, 118.711152);
        this.map = new window.qq.maps.Map(document.getElementById("myMap"), {
          center: center,
          zoom: 4,
          mapTypeId: window.qq.maps.MapTypeId.HYBRID,
          mapTypeControlOptions: {
            mapTypeIds: [
              // window.qq.maps.MapTypeId.ROADMAP,
              // window.qq.maps.MapTypeId.SATELLITE,
              // window.qq.maps.MapTypeId.HYBRID
            ],
            //设置控件位置相对上方中间位置对齐
            position: window.qq.maps.ControlPosition.TOP_LEFT,
          },
          panControlOptions: {position: window.qq.maps.ControlPosition.TOP_RIGHT},
          zoomControlOptions: {position: window.qq.maps.ControlPosition.RIGHT_TOP,}
        });
      }
    },
    // 获取所有的区域数据
    getRegionData() {
      let params = {
        PARENTSNOWID: '',
      }
      if (this.$store.state.sysType == "2") {
        params.PARENTSNOWID = this.$store.state.xzqSnowid
      }
      let that = this
      return new Promise(function (resolve) {
        that.$http.post("Region/GetDataTree", params)
            .then((response) => {
              if (response.data.SUCCESS) {
                let list = response.data.DATA
                if (that.$store.state.sysType == "2") {
                  that.cascadeTreeData = list
                  that.currentCascade = list[0].SNOWID
                } else {
                  list.forEach((item) => {
                    if (item.Child && item.Child.length > 0) {
                      item.Child.forEach((ele, index) => {
                        that.cascadeTreeData.push(ele)
                        if (index === 0 && !that.currentCascade) {
                          that.currentCascade = ele.SNOWID
                        }
                      })
                    }
                  })
                }
                resolve(that.currentCascade)
              } else {
                that.$message.error(response.data.MSG)
              }
            })
            .catch((error) => {
              that.errorFlag(JSON.stringify(error))
            });
      })
    },
    // 获取服务组织的数据
    getServiceOrganizationData(id) {
      let params = {
        SnowID: id
      }
      let that = this
      return new Promise(function (resolve, reject) {
        that.$http.post("Region/GetRegionCooperativeUser", params)
            .then((response) => {
              if (response.data.SUCCESS) {
                let list = response.data.DATA
                that.reginList = list
                resolve(list)
              } else {
                that.reginList = []
                that.$message.error(response.data.MSG)
              }
            })
            .catch((error) => {
              that.errorFlag(JSON.stringify(error))
            });
      })
    },
    // 获取农机数据
    getFarmMachineryData(id) {
      let params = {
        SysUserSnowID: id
      }
      let that = this
      return new Promise(function (resolve) {
        that.$http.post("Mechinery/GetDataList", params)
            .then((response) => {
              if (response.data.SUCCESS) {
                let list = response.data.DATA
                list.forEach((item) => {
                  item.DISPLAYNAME = item.NAME
                })
                resolve(list)
              } else {
                this.$message.error(response.data.MSG)
              }
            })
            .catch((error) => {
              this.errorFlag(JSON.stringify(error))
            });
      })
    },
    // 获取作业报告
    getOperationReportData(id, fId = '', sTime = '', eTime = '') {
      var year=new Date().getFullYear()
      let params = {
        pageIndex: 0,
        pageSize: 0,
        StartTime: year+'-01-01',
        EndTime: year+'-12-31',
        MechinerySnowID: id,
        SysUserSnowID: fId
      }
      if (sTime && eTime) {
        params.StartTime = sTime
        params.EndTime = eTime
      }
      let that = this
      return new Promise(function (resolve) {
        that.$http.post("WorkReport/GetDataList", params)
            .then((response) => {
              if (response.data.SUCCESS) {
                let list = response.data.DATA
                list.forEach((item) => {
                  item.DISPLAYNAME = item.OWERNAME + ' ' + item.STARTGPSTIME.split(' ')[0] + ' ' + that.getServiceLinkTypeText(item.SERVICELINK) + ' ' + item.DISTANCE
                  item.leaf = true
                })
                resolve(list)
              } else {
                this.$message.error(response.data.MSG)
              }
            })
            .catch((error) => {
              this.errorFlag(JSON.stringify(error))
            });
      })
    },
    getNServiceOrganizationData() {
      let params = {}
      let that = this
      return new Promise(function (resolve) {
        that.$http.post("CooperativeUser/GetDataList", params)
            .then((response) => {
              if (response.data.SUCCESS) {
                let list = response.data.DATA
                // this.reginList = list
                resolve(list)
              } else {
                this.$message.error(response.data.MSG)
              }
            })
            .catch((error) => {
              this.errorFlag(JSON.stringify(error))
            })
      })
    },
    async loadNode(node, resolve) {
      if (node.level === 0) {
        await this.getRegionData()
        this.reginList = await this.getServiceOrganizationData(this.cascadeTreeData[0].SNOWID)
        return resolve([]);
      }
      setTimeout(async () => {
        let data = ''
        if (node.level === 1) {
          data = await this.getFarmMachineryData(node.data.SNOWID);
        } else if (node.level === 2) {
          data = await this.getOperationReportData(node.data.SnowID)
        }
        resolve(data);
      }, 500);
    },
    nodeClick(node) {
      if (node.leaf) {
        if (this.trackLineList.length > 0) {
          this.trackLineList.forEach((item) => {
            item.setMap(null)
          })
          this.trackLineList = []
        }
        // 生成轨迹
        this.viewTrack(1, node)
      }
    },
    handleChange(node) {
      this.getServiceOrganizationData(node[node.length - 1])
    },
    // 查看轨迹
    viewTrack(i, data, flag = true) {
      let params = {
        PositionerSnowID: data.POSITIONERSNOWID,
        pageIndex: i,
        pageSize: 1000,
        StartTime: data.STARTGPSTIME, //开始时间不填返回今天数据
        EndTime: data.ENDGPSTIME //截止时间
      }
      let n = i + 1
      if (flag) {
        this.showLoading("请稍后")
      }
      return new Promise((resolve) => {
        this.$http.post("PositionerHistory/GetDataList", params)
            .then((response) => {
              if (response.data.SUCCESS) {
                let list = response.data.DATA
                let line = []
                for (var i = 0; i < list.length; i++) {
                  var d = list[i]
                  if (d.LAT != 0 && d.LON != 0) {
                    // this.trackList.push(d)
                    var zbs=window.wgs84ToGcj02(d.LAT,d.LON)
                    let point = new window.qq.maps.LatLng(zbs.latitude, zbs.longitude);
                    line.push(point)
                  }
                }
                if (line.length > 0) {
                  let obj = new window.qq.maps.Polyline({
                    map: this.map,
                    path: line,
                    strokeDashStyle: 'solid',
                    strokeWeight: 3,
                    strokeLinecap: 'butt',
                    strokeColor: new window.qq.maps.Color(237, 63, 20, 1),
                    zIndex: 1
                  });
                  this.trackLineList.push(obj)
                  if (list.length === 1000) {
                    this.map.panTo(line[0])
                    this.map.zoomTo(14)
                    if (flag) {
                      resolve(this.viewTrack(n, data))
                    } else {
                      resolve(this.viewTrack(n, data, false))
                    }
                  } else if (list.length !== 1000) {
                    // this.map.panTo(line[0])
                    // this.map.zoomTo(14)
                    if (flag) {
                      this.hideLoading()
                    }
                    resolve(true)
                  }
                }
              } else {
                this.$message.error(response.data.MSG)
                resolve(true)
              }
            })
            .catch((error) => {
              this.errorFlag(JSON.stringify(error))
              if (flag) {
                this.hideLoading()
              }
              resolve(true)
            });
      })
    },
    screenshotHandleClose() {
      this.screenshotDialogVisible = false
    },
    // 获取对应的服务环节
    getServiceLinkTypeText(type) {
      let text = ''
      switch (type) {
        case 0:
          text = '旋耕'
          break;
        case 1:
          text = '机播'
          break;
        case 2:
          text = '防治'
          break;
        case 3:
          text = '收割'
          break;
        case 4:
          text = '深翻'
          break;
        default:
          text = '暂无'
          break
      }
      return text
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.DISPLAYNAME.indexOf(value) !== -1;
    },
    saveImg(a, j) {
      let that = this
      return new Promise((resolve) => {
        setTimeout(() => {
          domtoimage.toPng(document.querySelector("#myMap")).then((dataUrl) => {
            // saveAs(dataUrl, a[0]?.SYSUSERNAME + '_' + a[j].MECHINERYNAME + '_' + a[j].STARTGPSTIME + ".png");
            let obj = {
              name: a[0]?.SYSUSERNAME + '_' + a[j].MECHINERYNAME + '_' + a[j].STARTGPSTIME + '.png',
              url: dataUrl
            }
            that.screenshotImgList.push(obj)
            if (that.trackLineList.length > 0) {
              that.trackLineList.forEach((item) => {
                item.setMap(null)
              })
              that.trackLineList = []
              resolve(true)
            }
          }).catch(function (error) {
            console.error('失败===', error);
          });
        }, 1000)
      })
    },
    // 进行截图
    takeScreenshot() {
      let startTime = '', endTime = '';
      let that = this
      let startLocation = this.map.getCenter()
      let startZoom = this.map.getZoom()
      if (this.screenshotDate.length > 0) {
        startTime = this.screenshotDate[0]
        endTime = this.screenshotDate[1]
      }
      domtoimage.toPng(document.querySelector("#myMap")).then(async (dataUrl) => {
        let newDom = document.createElement('img')
        newDom.className = 'overlayArea'
        newDom.src = dataUrl
        let dom = document.querySelector('.mainArea')
        // dom.appendChild(newDom)
        this.screenshotDialogVisible = false
        this.showLoading("正在下载图片,请稍后")
        // 进行地图位置的移动和轨迹的添加,为了防止卡顿,需要在完成一张图片的下载之后,删除之前地图上的轨迹
        // 先获取所有的服务组织
        let treeData = JSON.parse(JSON.stringify(this.reginList))
        let workpaperData = []
        for (let i = 0; i < treeData.length; i++) {
          let item = treeData[i]
          // 得到各个服务组织的作业报告,通过作业报告来查询轨迹
          let list = await this.getOperationReportData('', item.SNOWID, startTime, endTime)
          workpaperData.push(list)
        }
        for (let i = 0; i < workpaperData.length; i++) {
          let a = workpaperData[i]
          for (let j = 0; j < a.length; j++) {
            let flag = await this.viewTrack(1, a[j], false)
            if (a[j] && flag) {
              // 等待地图移动和缩放完成,防止截图时出现底图空白或轨迹不存在的情况(根据合作社下面的农机来进行截图)
              await that.saveImg(a, j)
            }
          }
        }
        console.log('下载完成')
        if (that.screenshotImgList.length > 0) {
          that.screenshotImgList.forEach((item) => {  // listOfData是含有图片的数据数组
            const basePic = item.url.replace(/^data:image\/(png|jpg);base64,/, "");  // 生成base64图片数据
            that.img.file(item.name, basePic, {base64: true});  // 将图片文件加入到zip包内
          })
          that.zip.generateAsync({type: "blob"})   // zip下载
              .then(function (content) {
                // see FileSaver.js
                saveAs(content, "截屏图片.zip");  // zip下载后的名字
              });
          that.screenshotImgList = []
        }
        setTimeout(() => {
          this.hideLoading()
          this.$nextTick(() => {
            let nDom = document.querySelector('.overlayArea')
            if (nDom) {
              nDom.remove()
            }
          })
          this.map.setCenter(startLocation)
          this.map.setZoom(startZoom)
        }, 4000)
      }).catch(function (error) {
        console.error('失败===', error);
      });
    },
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
}
</script>

<style>
.searchRegion {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 11;
}

.screenshotBtn {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.treeRegion .el-tree-node {
  margin-top: 0px;
}

.overlayArea {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 86vw;
  height: 86vh;
}
</style>