<template>
  <div class="views-main" style="overflow:auto;">
    <div class="views-main-title" style="position: relative;height: 100%;">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="作业列表" name="operationList">
          <div class="tableBox" style="position: static;height: 100%;">
            <div class="views-main-search" style="position: static">
              <el-select v-model="searchInfo.COOPERATIVEUSERSNOWID" placeholder="请选择服务组织" filterable clearable style="margin-right: 20px">
                <el-option v-for="item in serviceOrganizationList" :key="item.SYSUSERSNOWID" :label="item.SYSUSERDISPLAYNAME" :value="item.SYSUSERSNOWID"></el-option>
              </el-select>
<!--              <el-cascader-->
<!--                  ref="cascader"-->
<!--                  placeholder="请选择作业区域"-->
<!--                  v-model="currentCascade"-->
<!--                  :options="cascadeTreeData"-->
<!--                  :props="cascadeProps"-->
<!--                  @change="regCascaderChange"-->
<!--                  style="width: 300px;margin-right: 20px"></el-cascader>-->
              <el-date-picker
                  v-model="searchInfo.signDate"
                  style="margin-right: 20px"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
              <el-button type="primary" @click="searchPost">查询</el-button>
              <el-button @click="exportDocument" v-if="sysType == '1'" type="success" class="fr">导出文档</el-button>
            </div>
            <div class="views-main-body" style="position: static;height: 90%">
              <el-table :data="postList" @select="select" @select-all="selectAll" stripe style="width: 100%" tooltip-effect="dark">
                <el-table-column
                    v-if="sysType == '1'"
                    type="selection"
                    width="30">
                </el-table-column>
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <div class="farmerList">
                            <p class="farmerItem" v-for="item in props.row.FarMerList" :key="item.SNOWID">
                                {{item.NAME}}({{item.IDCARD}})
                            </p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="STARTGPSTIME" label="开始时间" width="200" fixed></el-table-column>
                <el-table-column prop="ENDGPSTIME" label="结束时间" width="200" fixed></el-table-column>
                <el-table-column prop="CREATETIME" label="作业时长(分钟)" width="200" fixed>
                  <template slot-scope="scope">
                    <div>{{getOperationTime(scope.row.STARTGPSTIME,scope.row.ENDGPSTIME)}}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="SYSUSERNAME" label="服务组织名称" width="150" ></el-table-column>
                <el-table-column prop="REGIONNAME" label="作业区域" width="150"></el-table-column>
                <el-table-column prop="CROPSSNAME" label="作物名称" width="150"></el-table-column>
                <el-table-column prop="SERVICELINK" label="服务环节" width="150">
                  <template slot-scope="scope">
                    <div>{{getServiceLinkTypeText(scope.row.SERVICELINK)}}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="AREA" label="有效作业面积(亩)" width="150"></el-table-column>
                <el-table-column prop="BREADTH" label="作业幅宽(米)" width="150"></el-table-column>
                <el-table-column prop="DISTANCE" label="行驶距离(米)" width="150"></el-table-column>
                <el-table-column prop="MECHINERYNAME" label="农机名称" width="150"></el-table-column>
                <el-table-column prop="OWERNAME" label="农机所有者" width="150"></el-table-column>
                <el-table-column label="操作" width="100" fixed="right">
                  <template slot-scope="scope">
<!--                    <el-button v-if="scope.row.isShowBtn" @click="viewTrack(scope.row)" type="text" size="small" style="color:#409EFF">查看轨迹</el-button>-->
                    <el-button @click="orientation(scope.row)" type="text" size="small" style="color:#409EFF">定位</el-button>
                    <!--              <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>-->
                    <!--              <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>-->
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <!--    地图标签页初始时不能切换,在点击查看轨迹和定位时自动切换到地图,如果用户切换回作业列表,那么此时地图标签页无法点击    -->
        <el-tab-pane label="地图" name="map" :disabled="isDisabled" style="position: relative">
          <div id="myMap" style="width: 86vw;height: 84vh"></div>
          <div class="mapInfo" style="z-index: 15;width: 280px;height: 240px;position: absolute;bottom: 10px;right: 10px">
            <el-card class="box-card" style="width: 100%;height: 100%">
              <div class="mapText">服务组织名称: {{currentUserObj.SYSUSERNAME}}</div>
              <div class="mapText">作物名称: {{currentUserObj.CROPSSNAME}}</div>
              <div class="mapText">服务环节: {{getServiceLinkTypeText(currentUserObj.SERVICELINK)}}</div>
              <div class="mapText">开始时间: {{currentUserObj.STARTGPSTIME}}</div>
              <div class="mapText">结束时间: {{currentUserObj.ENDGPSTIME}}</div>
              <div class="mapText">农机名称: {{currentUserObj.MECHINERYNAME}}</div>
              <div class="mapText">农机所有人: {{currentUserObj.OWERNAME}}</div>
            </el-card>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
export default {
  name: "OperationList",
  data() {
    var idcard = (rule, value, callback) => {
      let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (value === "") {
        callback(new Error("请输入身份证号码"));
      } else {
        if(reg.test(value) === false){
          callback(new Error("请输入正确的身份证号码"));
        }else{
          callback();
        }
      }
    };
    var phone = (rule, value, callback) => {
      let reg = /^1[3-9][0-9]{9}$/
      if (value === "") {
        callback(new Error("请输入电话号码"));
      } else {
        if(!reg.test(value)){
          callback(new Error("请输入正确的电话号码"));
        }else{
          callback();
        }
      }
    };
    return {
      sysType: '',
      canDo:true,
      serviceOrganizationList: [], //服务组织数据
      searchInfo: {
        COOPERATIVEUSERSNOWID: '', // 服务组织id
        contractCode: '', // 合同编号
        signDate: '', // 签订时间
        farmerName: '', // 农户名称
        serviceLinkItem: '', // 服务环节
        operatingArea: '', // 作业区域
      },
      currentCascade: '', // 当前级联选中
      cascadeTreeData: [],
      cascadeProps: {
        value: 'SNOWID',
        label: 'NAME',
        children: 'Child',
        checkStrictly: true,
      },
      selectList: [],
      serviceLinkList: [
        {
          name: '耕旋耕',
          value: 0
        },
        {
          name: '种机播',
          value: 1
        },
        {
          name: '防治',
          value: 2
        },
        {
          name: '收',
          value: 3
        },
        {
          name: '其他',
          value: 4
        },
      ], // 服务环节数组
      activeName: 'operationList', // 当前选中的标签页
      postList:[],
      PageIndex:1,
      PageSize:10,
      Total:0,
      map: null, // 地图对象
      isDisabled: true, // 是否禁用地图标签页的切换
      trackLineList: [], // 轨迹对象数组
      markerList: [],
      currentUserObj: {},
      doTypeStr:"",
      doType:"",
      postModal:false,
      postInfo:{},
    };
  },
  async mounted() {
    this.sysType = this.$store.state.sysType
    if(this.sysType =="2"){
      this.regId= this.$store.state.xzqSnowid
    }
    this.loadItem = null
    await this.getRegionData()
    this.getServiceOrganizationList()
    this.getPostList()
    this.initMap()
  },
  methods: {
    // 标签页切换
    handleClick(){
      if(this.activeName === 'operationList'){
        this.isDisabled = true
        if(this.trackLineList.length > 0){
          this.trackLineList.forEach((item)=>{
            item.setMap(null)
          })
        }
        this.markerList.forEach((item)=>{
          item.setMap(null)
        })
        this.markerList = []
      }
    },
    // 初始化地图
    initMap(){
      if(!this.map){
        var center = new window.qq.maps.LatLng(32.151818, 118.711152);
        this.map = new window.qq.maps.Map(document.getElementById("myMap"), {
          center: center,
          zoom: 16,
          mapTypeId: window.qq.maps.MapTypeId.HYBRID,
          mapTypeControlOptions: {
            mapTypeIds: [
              // window.qq.maps.MapTypeId.ROADMAP,
              // window.qq.maps.MapTypeId.SATELLITE,
              // window.qq.maps.MapTypeId.HYBRID
            ],
            //设置控件位置相对上方中间位置对齐
            position: window.qq.maps.ControlPosition.TOP_LEFT,
          },
          panControlOptions: { position: window.qq.maps.ControlPosition.TOP_RIGHT },
          zoomControlOptions: { position: window.qq.maps.ControlPosition.RIGHT_TOP, }
        });
      }
    },
    // 获取服务组织列表数据
    getServiceOrganizationList(){
      var params={}
      this.$http.post("CooperativeUser/GetDataList", params)
          .then((response) => {
            if (response.data.SUCCESS) {
              this.serviceOrganizationList = response.data.DATA
            } else {
              this.$message.error(response.data.MSG)
            }
          })
          .catch((error) => {
            this.errorFlag(JSON.stringify(error))
          });
    },
    getPostList(){
      this.showLoading("请稍后")
      var params={
        pageIndex:this.PageIndex,
        pageSize:this.PageSize,
        StartTime: '',
        EndTime: '',
        // NAME:this.name,
        SysUserSnowID: this.searchInfo.COOPERATIVEUSERSNOWID,
        RegionUserSnowID: this.currentCascade
      }
      if(Array.isArray(this.searchInfo.signDate) && this.searchInfo.signDate.length > 0){
        params.StartTime = this.searchInfo.signDate[0]
        params.EndTime = this.searchInfo.signDate[1]
      }
      if(this.sysType == 2){
        params.regionSnowID = this.regId
      }
      this.$http.post("WorkReport/GetDataList", params)
          .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
              // response.data.DATA.forEach((item)=>{
              //   this.getIsShowBtn(item)
              // })
              this.postList=response.data.DATA
              this.Total=response.data.TOTALCOUNT
            } else {
              this.postList=[]
              this.Total=0
              this.$message.error(response.data.MSG)
            }
          })
          .catch((error) => {
            this.errorFlag(JSON.stringify(error))
            this.hideLoading()
            this.postList=[]
            this.Total=0
          });
    },
    // 获取所有的区域数据
    getRegionData(){
      let params={
        PARENTSNOWID: '',
      }
      let that = this
      return new Promise(function (resolve) {
        that.$http.post("Region/GetDataTree", params)
            .then((response) => {
              if (response.data.SUCCESS) {
                let list = response.data.DATA
                list.forEach((item)=>{
                  if(item.Child && item.Child.length > 0){
                    item.Child.forEach((ele,index)=>{
                      that.cascadeTreeData.push(ele)
                      // if(index === 0){
                      //   that.currentCascade = ele.SNOWID
                      // }
                    })
                  }
                })
                resolve(that.currentCascade)
              } else {
                that.$message.error(response.data.MSG)
              }
            })
            .catch((error) => {
              that.errorFlag(JSON.stringify(error))
            });
      })
    },
    // 级联选中限制
    regCascaderChange(e) {
      if(e.length === 1){
        this.currentCascade = ''
        this.$nextTick(() => {
          // 清空级联选择器选中状态
          this.$refs.cascader.$refs.panel.clearCheckedNodes()
          // 清除高亮
          this.$refs.cascader.$refs.panel.activePath = []
        })
        return this.$message.error('选中区域错误,只能选中镇或者街道')
      }
      if (e.length == 0) {
        this.currentCascade = ""
      } else {
        this.currentCascade = e[e.length - 1]
      }
    },
    // 计算作业时长
    getOperationTime(sTime,eTime){
      let startDate = new Date(sTime);
      let stopDate = new Date(eTime);
      let startTime = startDate.getTime();
      let stopTime=stopDate.getTime();
      let cTime=Number(stopTime)-Number(startTime);
      let secondTime=cTime/1000/60;
      return parseInt(secondTime);
    },
    searchPost(){
      this.PageIndex=1
      this.getPostList()
    },
    getIsShowBtn(data){
      let params = {
        MechinerySnowID: data.MECHINERYSNOWID,
        pageIndex: 1,
        pageSize: 5,
        StartTime: data.STARTGPSTIME.split(' ')[0],
        EndTime: data.ENDGPSTIME.split(' ')[0]
      }
      this.$http.post("WorkReport/GetMechineryWorkModels", params)
          .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
              if(response.data.DATA.length === 0){
                data.isShowBtn = false
              }else{
                if(response.data.DATA[0].WorkAreaCount === null){
                  data.isShowBtn = false
                }else{
                  data.isShowBtn = true
                }
              }
            } else {
              this.$message.error(response.data.MSG)
            }
          })
          .catch((error) => {
            this.errorFlag(JSON.stringify(error))
            this.hideLoading()
          });
    },
    // 查看轨迹
    viewTrack(i,data){
      let params = {
        PositionerSnowID: data.POSITIONERSNOWID,
        pageIndex: i,
        pageSize: 2000,
        StartTime: data.STARTGPSTIME, //开始时间不填返回今天数据
        EndTime: data.ENDGPSTIME //截止时间
      }
      let n = i + 1
      this.$http.post("PositionerHistory/GetDataList", params)
          .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
              let list = response.data.DATA
              let line = []
              for (var i = 0; i < list.length; i++) {
                var d = list[i]
                if (d.LAT != 0 && d.LON != 0) {
                  // this.trackList.push(d)
                  var zbs=window.wgs84ToGcj02(d.LAT,d.LON)
                  let point = new window.qq.maps.LatLng(zbs.latitude, zbs.longitude);
                  line.push(point)
                }
              }
              if (line.length > 0) {
                let obj = new window.qq.maps.Polyline({
                  map: this.map,
                  path: line,
                  strokeDashStyle: 'solid',
                  strokeWeight: 3,
                  strokeLinecap: 'butt',
                  strokeColor: new window.qq.maps.Color(237, 63, 20, 1),
                  zIndex: 1
                });
                this.trackLineList.push(obj)
                if(list.length === 2000){
                  this.viewTrack(n,data)
                }
                // this.map.panTo(line[0])
                // this.map.zoomTo(16)
              }
            } else {
              this.$message.error(response.data.MSG)
            }
          })
          .catch((error) => {
            this.errorFlag(JSON.stringify(error))
            this.hideLoading()
          });
    },
    // 定位
    orientation(data){
      this.isDisabled = false
      this.activeName = 'map'
      let params = {
        pageIndex: 0,
        pageSize: 0,
        snowID: data.POSITIONERSNOWID
      }
      this.currentUserObj = data
      setTimeout(()=>{
        this.viewTrack(1,data)
      },300)
      // REGIONSNOWID
      this.$http.post("Positioner/GetDataList", params)
          .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
              if(response.data.DATA.length > 0){
                let dom = response.data.DATA[0]
                var zbs=window.wgs84ToGcj02(dom.LAT,dom.LON)
                let center = new window.qq.maps.LatLng(zbs.latitude, zbs.longitude);
                let marker = new window.qq.maps.Marker({
                  position: center,
                  map: this.map
                });
                this.markerList.push(marker)
                this.map.panTo(center)
              }
            } else {
              this.$message.error(response.data.MSG)
            }
          })
          .catch((error) => {
            this.errorFlag(JSON.stringify(error))
            this.hideLoading()
          });
    },
    PGChange(e){
      this.PageIndex=e
      this.getPostList()
    },
    // 获取对应的服务环节
    getServiceLinkTypeText(type){
      let text = ''
      switch (type) {
        case 0:
          text = '旋耕'
          break;
        case 1:
          text = '机播'
          break;
        case 2:
          text = '防治'
          break;
        case 3:
          text = '收割'
          break;
        case 4:
          text = '深翻'
          break;
        default:
          text = '暂无'
          break
      }
      return text
    },
    select(node){
      this.selectList = node
    },
    selectAll(node){
      this.selectList = node
    },
    // 导出文档
    exportDocument(){
      if(this.selectList.length > 0){
        this.showLoading("请稍后")
        this.selectList.forEach((item)=> {
          let params = {
            SysUserSnowID: item.SYSUSERSNOWID,
            StartTime: '',
            EndTime: ''
          }
          if(this.searchInfo.signDate.length > 0){
            params.StartTime = this.searchInfo.signDate[0]
            params.EndTime = this.searchInfo.signDate[1]
          }
          this.$download.post("WorkReport/ExportReportList", params, {responseType: 'blob', timeout: 300000})
              .then((resJson) => {
                this.hideLoading()
                this.canDo = true
                if (resJson) {
                  var name = item.SYSUSERNAME
                  let blob = new Blob([resJson.data], {type: 'application/octet-stream'})
                  if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveBlob(blob, name + ".xls")
                  } else {
                    let downloadElement = document.createElement('a')
                    let href = window.URL.createObjectURL(blob) //创建下载的链接
                    downloadElement.href = href
                    downloadElement.download = name + ".xls"
                    document.body.appendChild(downloadElement)
                    //此写法兼容火狐
                    let evt = document.createEvent('MouseEvents')
                    evt.initEvent('click', false, false)
                    downloadElement.dispatchEvent(evt)
                    document.body.removeChild(downloadElement)
                  }
                  this.$message.success('操作成功!')
                } else {
                  this.$message.error("数据导出失败")
                }

              })
        })
      }else{
        this.$message.error('请选择要导出的数据!')
      }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.mapInfo .el-card__body{
  padding: 10px;
}
.mapText{
  margin-bottom: 10px;
  font-size: 16px;
}
.topText{
  margin-right: 10px;
  color: grey;
  font-weight: 700;
}
.topText > span{
  color: red;
}
.views-main-title .el-tabs.el-tabs--top{
  position: relative;
  height: 100%;
}
#pane-operationList{
  height: 100%;
}
.views-main-title .el-tabs.el-tabs--top .el-tabs__content{
  height: 92%;
}
.el-table__body-wrapper.is-scrolling-none{
  overflow-y: auto;
  max-height: calc(100vh - 350px);
}
.farmerList{overflow:hidden;padding:0px 12px;}
.farmerList .farmerItem{background-color:#F1F1F1;border-radius:3px;padding:4px 10px 4px 10px;font-size:12px;float:left;margin-right:12px;}
</style>