<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">行政区管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input placeholder="输入关键字进行过滤" v-model="filterText" style="width:200px;"> </el-input>
                <el-button type="primary" @click="sendQuery" class="ml10">查询</el-button>
                <el-button type="success" @click="addDep" v-if="sysType == '1'" class="ml10">添加行政区域</el-button>
            </div>
            <div class="views-main-body">
                <el-tree :props="props" :load="getList" lazy ref="tree" node-key="snowid" :filter-node-method="filterNode"
                    v-if="loadReg">
                    <span class="custom-tree-node" slot-scope="{ node, data }">
                        <span class="treeName">{{ node.label }}</span>
                        <span class="managerName">({{ data.code }})</span>
                        <span v-if="sysType == '1'">
                            <el-button v-if="data.type < 3" type="text" size="mini" @click.stop="() => append(node, data)" style="color:#19be6b">
                                添加
                            </el-button>
                            <el-button type="text" size="mini" @click.stop="() => edit(node, data)" style="color:#ff9900">
                                编辑
                            </el-button>
                            <el-button v-if="data.type == 2" type="text" size="mini" @click.stop="() => users(node, data)" style="color:#409EFF">
                                用户
                            </el-button>
                            <el-button v-if="data.type > 1" type="text" size="mini" @click.stop="() => exportTrail(node, data)" style="color:#409EFF">
                                作业轨迹
                            </el-button>
                            <el-button type="text" size="mini" @click.stop="() => remove(node, data)" style="color:#ed4014">
                                删除
                            </el-button>
                        </span>
                    </span>
                </el-tree>
            </div>
        </div>
        <el-dialog :title="DepModalTitle" :visible.sync="DepModal" width="500px" :close-on-click-modal="false">
            <el-form :model="DepInfo" status-icon :rules="DepRules" ref="DepForm" label-width="110px" class="demo-DepForm">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="DepInfo.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="编号" prop="code">
                    <el-input v-model="DepInfo.code" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="级别"  prop="REGIONTYPE">
                  <el-input :disabled="true" type="number" min="1" v-model="DepInfo.REGIONTYPE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitDepForm()">提交</el-button>
                    <el-button @click="closeDepForm()">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :title="usersModalTitle" :visible.sync="usersModal" width="1600px" top="30px" :close-on-click-modal="false">
            <RegionUsers :regionid="regionid" :tableHeight="dialogMaxHeigh" ref="users"></RegionUsers>
        </el-dialog>
        <el-dialog :title="trailTitle" :visible.sync="exportModal" width="1600px" top="30px" :close-on-click-modal="false">
          <div style="width:100%;overflow:hidden;margin-bottom:20px;">
            <el-select v-model="exportProject" placeholder="点击选择项目" filterable clearable class="iww">
              <el-option v-for="item in projects" :key="item.SNOWID" :label="item.NAME"
                          :value="item.SNOWID"></el-option>
            </el-select>
            <el-select v-model="exportCreate" placeholder="点击选择状态" filterable clearable class="iww">
                <el-option label="已生成" value="1"></el-option>
                <el-option label="未生成" value="0"></el-option>
            </el-select>
            <el-date-picker v-model="exportTimes" type="datetimerange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" :clearable="false" class="iwss"> </el-date-picker>
            <el-button type="primary" @click="searchExport">查询</el-button>
            <el-button v-if="sysType == 1" type="success" @click="addExport" class="fr">导出</el-button>
          </div>
          <el-table :data="exportTable" stripe style="width: 100%" :height="dialogMaxHeigh">
              <el-table-column prop="ProjectSnowName" label="项目">
                  <template slot-scope="scope">
                      <span v-if="scope.row.ProjectSnowName">{{ scope.row.ProjectSnowName}}</span>
                      <span v-else>/</span>
                  </template>
              </el-table-column>
              <el-table-column prop="StartTime" label="开始时间"></el-table-column>
              <el-table-column prop="EndTime" label="结束时间"></el-table-column>
              <el-table-column prop="CREATED" label="是否创建">
                <template slot-scope="scope">
                      <span v-if="scope.row.CREATED == 1" style="color:#67C23A;">已创建</span>
                      <span v-else style="color:#E6A23C;">未创建</span>
                  </template>
              </el-table-column>
              <el-table-column label="操作" width="100">
                  <template slot-scope="scope">
                      <el-button  v-if="scope.row.PATH!='' && scope.row.CREATED == 1" @click="downLoadExport(scope.row.PATH)" type="text" size="small"
                          style="color:#409EFF">下载</el-button>
                  </template>
              </el-table-column>
          </el-table>
          <el-pagination background layout="total, prev, pager, next" :current-page="PageIndex" :page-size="PageSize"
              :total="Total" class="pg" @current-change="PGChange"></el-pagination>
        </el-dialog>
        <el-dialog :title="trailTitle" :visible.sync="trailModal" width="900px" :close-on-click-modal="false">
          <el-form :model="trailInfo" status-icon :rules="trailrules" ref="tForm" label-width="150px" class="demo-uForm">
            <el-form-item label="时间" prop="trailTimes">
              <el-date-picker v-model="trailInfo.trailTimes" type="datetimerange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" :clearable="false" style="width:100%;"> </el-date-picker>
            </el-form-item>
            <el-form-item label="项目">
              <el-select v-model="trailInfo.projectSnowID" style="width:100%;" clearable>
                <el-option v-for="item in projects" :key="item.SNOWID" :label="item.NAME"
                          :value="item.SNOWID"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitExportTrail()">提交</el-button>
              <el-button @click="trailModal = false">取消</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
    </div>
</template>
<script>
var h = document.body.clientHeight
import RegionUsers from "../../components/RegionUsers"
export default {
    name: "name",
    components: {
        RegionUsers,
    },
    data() {
        var name = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入名称"));
            } else {
                callback();
            }
        };
        var code = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入编号"));
            } else {
                callback();
            }
        };
        var REGIONTYPE = (rule, value, callback) => {
          if (value === "") {
            callback(new Error("请输入等级"));
          } else {
            callback();
          }
        };
        var trailTimes=(rule, value, callback) => {
          if (value ==='' && this.trailInfo.projectSnowID==='') {
            callback(new Error("请选择日期"));
          } else {
            callback();
          }
        };
        return {
            sysType: "",
            regId: "",
            loadReg: false,
            props: {
                label: 'name',
                children: 'zones',
                isLeaf: 'leaf'
            },
            filterText: "",

            DepModalType: "",
            DepModalTitle: "",

            DepParentID: "",
            DepParentNAME: "",

            DepModal: false,
            DepInfo: {
            },
            DepRules: {
                name: [{ validator: name, trigger: "blur" }],
                code: [{ validator: code, trigger: "blur" }],
                REGIONTYPE: [{ validator: REGIONTYPE, trigger: "blur" }],
            },
            DepEditItem: null,
            canDo: true,

            dialogMaxHeigh:0,
            regionid: "",
            usersModalTitle: "",
            usersModal: false,

            exportModal:false,
            exportTable:[],
            exportInfo:null,
            PageIndex: 1,
            PageSize: 10,
            Total: 0,
            exportProject:"",
            exportCreate:"",
            exportTimes:[],


            projects:[],
            trailTitle:"",
            trailInfo:{},
            trailrules:{
              trailTimes:[{validator: trailTimes, trigger: "blur"}],
            },
            trailModal:false,
        };
    },
    mounted() {
        this.sysType = this.$store.state.sysType
        if (this.sysType == "2") {
            this.regId = this.$store.state.xzqSnowid
        }
        this.dialogMaxHeigh = h - 300
        this.loadItem = null
        this.loadReg = true
    },
    methods: {
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        getList(node, resolve) {
            var snowid = ""
            let url = ''
            let that = this
            if (node.data != undefined) {
                snowid = node.data.snowid
                url = 'Region/GetDataList'
            }
            if(this.sysType == 2){
              if(this.sysType == 2 && !node.data && node.level === 0){
                // 如果是区域用户登录需要查找用户的当前树形数据和父级数据
                snowid = this.regId
                url = 'Region/GetDataTree'
              }
              if(node?.data?.zones && node?.data?.zones.length > 0){
                resolve(node.data.zones)
              }else{
                setTimeout(() => {
                  this.$http.post(url, { PARENTSNOWID: snowid })
                      .then(async (response) => {
                        if (response.data.SUCCESS) {
                          var l = response.data.DATA
                          var d = []
                          if (snowid == "" && this.sysType == "2" && this.regId != "") {
                            for (var i = 0; i < l.length; i++) {
                              if (l[i].SNOWID == this.regId) {
                                d.push({
                                  snowid: l[i].SNOWID,
                                  name: l[i].NAME,
                                  code: l[i].CODE,
                                  pid: l[i].PARENTSNOWID,
                                  type: l[i].REGIONTYPE,
                                  leaf: false,
                                })
                              }
                            }
                          } else {
                            for (var j = 0; j < l.length; j++) {
                              d.push({
                                snowid: l[j].SNOWID,
                                name: l[j].NAME,
                                code: l[j].CODE,
                                pid: l[j].PARENTSNOWID,
                                type: l[j].REGIONTYPE,
                                leaf: false,
                              })
                            }
                          }
                          if(node.level === 0){
                            let pTree = await that.getParentLevelTree(snowid)
                            if(pTree.length > 0){
                              pTree[0].zones = d
                            }
                            resolve(pTree);
                          }else{
                            resolve(d);
                          }
                        } else {
                          this.$message.error(response.data.MSG)
                        }
                      }).catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                  });
                }, 100)
              }
            }else{
              setTimeout(() => {
                this.$http.post('Region/GetDataList', { PARENTSNOWID: snowid })
                    .then(async (response) => {
                      if (response.data.SUCCESS) {
                        var l = response.data.DATA
                        var d = []
                        if (snowid == "" && this.sysType == "2" && this.regId != "") {
                          for (var i = 0; i < l.length; i++) {
                            if (l[i].SNOWID == this.regId) {
                              d.push({
                                snowid: l[i].SNOWID,
                                name: l[i].NAME,
                                code: l[i].CODE,
                                pid: l[i].PARENTSNOWID,
                                type: l[i].REGIONTYPE,
                                leaf: false,
                              })
                            }
                          }
                        } else {
                          for (var j = 0; j < l.length; j++) {
                            d.push({
                              snowid: l[j].SNOWID,
                              name: l[j].NAME,
                              code: l[j].CODE,
                              pid: l[j].PARENTSNOWID,
                              type: l[j].REGIONTYPE,
                              leaf: false,
                            })
                          }
                        }
                        resolve(d);
                      } else {
                        this.$message.error(response.data.MSG)
                      }
                    }).catch((error) => {
                  this.errorFlag(JSON.stringify(error))
                });
              }, 100)
            }
        },
        // 获取父级树形
        getParentLevelTree(id){
          return new Promise((resolve)=>{
            this.$http.post('Region/GetDataFather', { snowid: id })
                .then((response) => {
                  if (response.data.SUCCESS) {
                    var l = response.data.DATA
                    var d = []
                    l.forEach((item)=>{
                      d.push({
                        snowid: item.SNOWID,
                        name: item.NAME,
                        code: item.CODE,
                        pid: item.PARENTSNOWID,
                        type: item.REGIONTYPE,
                        zones: item.Child,
                        leaf: false,
                      })
                    })
                    resolve(d);
                  } else {
                    this.$message.error(response.data.MSG)
                  }
                }).catch((error) => {
              this.errorFlag(JSON.stringify(error))
            });
          })
        },
        sendQuery() {
            var val = this.filterText
            this.$refs.tree.filter(val)
        },
        closeDepForm() {
            this.DepModal = false
        },
        submitDepForm() {
            if (this.canDo) {
                this.$refs.DepForm.validate((valid) => {
                    if (valid) {
                        if (this.DepModalType == "1") {
                            this.appendDep()
                        } else if (this.DepModalType == "2") {
                            this.editDep()
                        }
                    } else {
                        return false;
                    }
                });
            }
        },
        appendDep() {
            this.canDo = false
            this.showLoading("请稍后")
            this.$http.post("Region/InsertData", this.DepInfo)
                .then((response) => {
                    this.hideLoading()
                    this.canDo = true
                    this.DepModal = false
                    if (response.data.SUCCESS) {
                        var data = {
                            name: this.DepInfo.name,
                            code: this.DepInfo.code,
                            type: this.DepInfo.REGIONTYPE,
                            snowid: response.data.DATA,
                            pid: this.DepParentID,
                            leaf: false,
                        }
                        var pid = null
                        if (this.DepParentID != "") {
                            pid = this.DepParentID
                        }
                        this.$refs.tree.append(data, pid)
                        this.$message.success("添加成功")
                    } else {
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },
        editDep() {
            this.canDo = false
            this.showLoading("请稍后")
            this.$http.post("Region/UpdateData", this.DepInfo)
                .then((response) => {
                    this.hideLoading()
                    this.canDo = true
                    this.DepModal = false
                    if (response.data.SUCCESS) {
                        this.$set(this.DepEditItem, 'name', this.DepInfo.name)
                        this.$set(this.DepEditItem, 'code', this.DepInfo.code)
                        this.$message.success("编辑成功")
                    } else {
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },
        addDep() {
            this.DepInfo = {
                name: "",
                code: "",
              REGIONTYPE: 1,
                parentSnowid: "",
            },
                this.DepModalType = "1"
            this.DepParentID = ""
            this.DepModalTitle = "添加行政区"
            this.DepModal = true
        },
        append(node, data) {
            this.DepInfo = {
                name: "",
                code: "",
              REGIONTYPE: data.type + 1,
                parentSnowid: data.snowid,
            },
                this.DepModalType = "1"
            this.DepParentID = data.snowid
            this.DepModalTitle = "添加行政区"
            this.DepModal = true
        },
        edit(node, data) {
            this.DepInfo = {
                SnowID: data.snowid,
                name: data.name,
                parentsnowid: data.pid,
                code: data.code,
              REGIONTYPE: data.type,
                CREATORNAME: this.$store.state.name
            },
                this.DepEditItem = data
            this.DepModalType = "2"
            this.DepParentID = data.pid
            this.DepModalTitle = "编辑行政区"
            this.DepModal = true

        },
        remove(node, data) {
            if (this.canDo) {
                this.$confirm('确定删除' + data.name + '及其全部的子区域？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.delDep(data)
                }).catch(() => {
                    this.$message.info("已取消")
                });
            }
        },
        delDep(data) {
            this.canDo = false
            this.showLoading("请稍后")
            this.$http.post("Region/DeleteData", { SnowID: data.snowid })
                .then((response) => {
                    //console.log(response)
                    this.hideLoading()
                    this.canDo = true
                    if (response.data.SUCCESS) {
                        console.log(data)
                        this.$refs.tree.remove(data)
                        this.$message.success("删除成功")
                    } else {
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.errorFlag(JSON.stringify(error))
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },
        users(node, data) {
            this.regionid= data.snowid
            this.usersModalTitle=data.name+"-用户管理"
            this.usersModal=true
            setTimeout(() => {
                this.$refs.users.getPostList()
            }, 100);
        },
        getProjectsList() {
          this.$http.post("Project/GetDataList", {})
              .then((response) => {
                if (response.data.SUCCESS) {
                  this.projects = response.data.DATA
                }
              }).catch((error) => {
            this.errorFlag(JSON.stringify(error))
          });
        },
        exportTrail(node,data){
            this.getProjectsList()
            this.trailTitle=data.name+'-作业轨迹'
            this.exportInfo=data
            this.PageIndex=1
            this.Total=0
            this.exportProject=""
            this.exportCreate=""
            this.exportTimes=[]
            this.exportTable=[]
            this.searchExport()
            this.exportModal=true
        },
        PGChange(e) {
            this.PageIndex = e
            this.searchExport()
        },
        searchExport(){
            this.showLoading("请稍后")
            var st=""
            var et=""
            if(this.exportTimes.length==2){
              st=this.comjs.getDateStrByDate(this.exportTimes[0])
              et=this.comjs.getDateStrByDate(this.exportTimes[1])
            }
            var cd=""
            if(this.exportCreate!=""){
              cd=parseInt(this.exportCreate)
            }
            var params = {
                PAGEINDEX: this.PageIndex,
                PAGESIZE: this.PageSize,
                Created: cd,
                RegionSnowID: this.exportInfo.snowid,
                ProjectSnowID: this.exportProject,
                StartTime:st,
                EndTime:et,
            }
            this.$http.post("PositionerHistory/GetHistoryFileList", params)
                .then((response) => {
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.exportTable = response.data.DATA
                        this.Total = response.data.TOTALCOUNT
                    } else {
                        this.exportTable = []
                        this.Total = 0
                    }
                })
                .catch((error) => {
                    this.hideLoading()
                    this.exportTable = []
                    this.Total = 0
                });
        },
        downLoadExport(path){
          window.open(path)
        },
        addExport(){
          this.trailInfo={
              RegionSnowID:this.exportInfo.snowid,
              trailTimes:"",
              projectSnowID:"",
            }
            this.trailModal=true
        },

        submitExportTrail(){
          if (this.canDo) {
            this.$refs.tForm.validate((valid) => {
              if (valid) {
                this.sendExportTrail()
              } else {
                return false;
              }
            });
          }
        },
        sendExportTrail(){
          this.canDo = false
          this.showLoading("请稍后")
          var st = this.comjs.getDateStrByDate(this.trailInfo.trailTimes[0])
          var et = this.comjs.getDateStrByDate(this.trailInfo.trailTimes[1])
          var params={
            RegionSnowID:this.trailInfo.RegionSnowID,
            projectSnowID:this.trailInfo.projectSnowID,
            startTime:st,
            endTime:et,
          }
          this.$http.post("PositionerHistory/GetRegionHistory", params)
              .then((response) => {
                this.canDo = true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success('后台正在生成文件，请留意生成记录')
                    this.searchExport()
                    this.trailModal=false
                } else {
                  this.$message.error('生成失败')
                }
              })
              .catch((error) => {
                this.errorFlag(JSON.stringify(error))
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo = true
              });
        }
    },
    computed: {
    },
    watch: {
    },
};
</script>
<style >
.el-tree-node {
    margin-top: 20px;
}

.treeName {
    font-size: 16px;
    margin-right: 5px;
}

.funTreeRow {
    width: 500px;
}

.funtreeName {
    font-size: 15px;
    margin-right: 30px;
}

.funItemList {
    float: right;
}

.managerName {
    font-size: 12px;
    margin-right: 20px;
    color: #409EFF;
}

.custom-tree-node {
    display: block;
}

.el-checkbox {
    margin-right: 15px !important;
}

.el-checkbox__label {
    padding-left: 5px !important;
}
</style>
